body {
  .adecco-group-theme {
    //Style
    --breadcrumb-height: 50px;
    --globalPaddingButton: 10px;
    --globalPaddingCard: 25px;
    --globalBorderRadius: 30px;

    --turquoise: #5cb8b2;
    --turquoise90: rgba(92, 184, 178, 0.9);
    --turquoise70: rgba(92, 184, 178, 0.7);
    --turquoise50: rgba(92, 184, 178, 0.5);
    --turquoise30: rgba(92, 184, 178, 0.3);
    --turquoise10: rgba(92, 184, 178, 0.1);

    --primaryColor: var(--white);
    --headerColor: var(--primaryColor);
    --secondaryColor: var(--turquoise);
    --componentColor: var(--secondaryColor);
    --titleHomeProcessColor: var(--black);
    --tabSelectedColor: var(--componentColor);

    //Fonts colors
    --headerFontColor: var(--gray800);
    --breadcrumbFontColor: var(--secondaryColor);
    --linkFontColor: var(--primaryColor);
    --tableHeaderColor: var(--gray800);

    //Button colors
    --primaryButtonColor: var(--secondaryColor);
    --secondaryButtonColor: var(--primaryColor);
    --hoverButtonColor: #48a19b;
    --disableButtonColor: #c8c7c7;
    --focusButtonColor: #782f40;

    //Active buttons
    --primaryButtonFontColor: var(--primaryColor);
    --primaryButtonBorderColor: var(--primaryButtonColor);

    --secondaryButtonFontColor: var(--primaryButtonColor);
    --secondaryButtonBorderColor: var(--primaryButtonColor);

    //Hover Buttons
    --primaryButtonHoverColor: var(--hoverButtonColor);
    --primaryButtonHoverFontColor: var(--primaryColor);
    --primaryButtonHoverBorderColor: var(--hoverButtonColor);

    --secondaryButtonHoverColor: var(--primaryColor);
    --secondaryButtonHoverFontColor: var(--hoverButtonColor);
    --secondaryButtonHoverBorderColor: var(--hoverButtonColor);

    //Disable Buttons
    --primaryButtonInactiveColor: var(--disableButtonColor);
    --primaryButtonInactiveFontColor: var(--primaryColor);
    --primaryButtonInactiveBorderColor: var(--disableButtonColor);

    --secondaryButtonInactiveColor: var(--primaryColor);
    --secondaryButtonInactiveFontColor: var(--disableButtonColor);
    --secondaryButtonInactiveBorderColor: var(--disableButtonColor);

    --mainColor: var(--white);
    --secondaryColor: var(--turquoise);

    --dropdownBackgroundColor: var(--white);
    --dropdownElementHoverColor: #e2e2e2;
    --mainLogo: url("../images/adecco-group-theme/common/logo2.png");
    --mainLogoColored: url("../images/adecco-group-theme/common/logo.png");
    --homeTopImage: none;
    --buttonPrimaryActiveColor: var(--turquoise);
    --buttonPrimaryBorderColor: var(--turquoise);
    --buttonPrimaryActiveFontColor: var(--white);
    --buttonPrimaryHoverColor: #48a19b;
    --buttonPrimaryHoverBorderColor: #48a19b;
    --buttonPrimaryHoverFontColor: var(--white);
    --buttonPrimaryPressedColor: #782f40;
    --buttonPrimaryPressedFontColor: var(--white);
    --buttonPrimaryInactiveColor: #c8c7c7;
    --buttonPrimaryInactiveFontColor: var(--white);
    --buttonSecondaryActiveColor: var(--white);
    --buttonSecondaryBorderColor: #5cb8b2;
    --buttonSecondaryActiveFontColor: #5cb8b2;
    --buttonSecondaryHoverColor: var(--white);
    --buttonSecondaryHoverBorderColor: #48a19b;
    --buttonSecondaryHoverFontColor: #48a19b;
    --buttonSecondaryPressedColor: #782f40;
    --buttonSecondaryPressedFontColor: #782f40;
    --buttonSecondaryInactiveColor: #c8c7c7;
    --buttonSecondaryInactiveFontColor: #c8c7c7;
    --buttonBlueActiveColor: #00b5f0;
    --buttonBlueActiveFontColor: var(--turquoise);
    --buttonBlueHoverColor: #0096ef;
    --buttonBlueHoverFontColor: var(--white);
    --buttonBluePressedColor: #196fc9;
    --buttonBluePressedFontColor: var(--white);
    --helpButton: url("../images/adecco-group-theme/candidate/help-button.png");
    --phaseCompleted: url("../images/adecco-group-theme/candidate/phase-completed.png");
    --phaseFailed: url("../images/adecco-group-theme/candidate/phase-failed.png");
    --dropdown: url("../images/adecco-group-theme/common/dropdown.png");
    --candidatePublicBackgroundStart: #5cb8b2;
    --candidatePublicBackgroundEnd: #48a19b;

    // removed as per TAG Spain request
    // --candidateDesktopArrowTop: url("../images/adecco-group-theme/candidate-web/top-desktop.png");
    // --candidateDesktopArrowBottom: url("../images/adecco-group-theme/candidate-web/bottom-desktop.png");
    // --candidateMobileArrowTop: url("../images/adecco-group-theme/candidate-web/arrow-public-top.png");
    // --candidateMobileArrowBottom: url("../images/adecco-group-theme/candidate-web/arrow-public-bottom.png");
    // --candidateLightArrowTop: url("../images/candidate-web/chevron.png");
    // --candidateLightArrowBottom: url("../images/candidate-web/Group@3x-2.png");
    // --candidateLightArrowInvert: url("../images/candidate-web/chevron-invert.png");
    --candidateDownloadPdf: url("../images/candidate-web/download-pdf.png");
    --adminServiceFeatureBackground: var(--gray100);
    --adminServiceFeatureBorder: var(--gray200);

    //Font Family
    --fontFamily: "Neutraface";
    --fontFamilyUrl: "https://fonts.googleapis.com/css?family=Comfortaa:300,400,500,600,700&display=swap";
    --fontFamilyHeaders: "Archer";
    --fontFamilyBody: "Neutraface";

    //specific header style
    #app-header {
      .-header {
        .-rc-text,
        .-tenant-btn,
        .-language-btn {
          margin: auto 0;
        }
        .-home-icon {
          margin: auto 1rem auto 1rem;
        }
        .-profile {
          color: var(--secondaryColor);
        }
      }

      .tooltip-inner:after,
      .tooltip-inner:before {
        bottom: 100%;
        left: 83%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
      .tooltip-inner {
        background-color: #fbfbfb !important;
        border-radius: 3px !important;
        width: 250px !important;
        max-width: 250px !important;
        top: 40px !important;
        left: -190px !important;
        font-weight: 400 !important;
        font-size: 13px;
      }

      .tooltip-inner:after {
        border-color: rgba(136, 183, 213, 0);
        border-bottom-color: #fbfbfb;
        border-width: 5px;
        margin-left: -5px;
      }
      .tooltip-inner:before {
        border-color: rgba(194, 225, 245, 0);
        border-bottom-color: var(--gray200);
        border-width: 6px;
        margin-left: -6px;
      }
    }

    #sidebar {
      top: 50px;
    }

    //specific breadcrum style
    .breadcrumbs-container {
      padding-left: 50px;
      z-index: 10;
      height: var(--breadcrumb-height);
    }

    #recruiter-profile .-profile-header {
      margin-top: -50px;
    }
  }
}
