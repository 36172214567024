.adecco-ui {
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: $white;
    @include themes($themes) {
      background-color: $white;
    }
    background-clip: border-box;
    border: $light-border;
  }

  .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    border-bottom: $light-border;
  }

  .card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
  }
  .card-footer {
    padding: 0.75rem 1.25rem;

    border-top: $light-border;
  }
}
