.-font-weight-bold {
  font-weight: bold !important;
}

.tag-ds.modal-wrapper .modal.-feedback-modal {
  width: min-content;
  max-width: 750px;
  form {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .modal-body {
      color: var(--text-01);
      .dropdown {
        width: 100%;
      }
    }
    .modal-footer {
      flex: 1;
    }
  }
}

.-feedback-remember {
  font-size: 12px;
  color: var(--text-02);
}
