.adecco-ui {
  .breadcrumb {
    position: relative;
    background-color: $white;
    @include themes($themes) {
      background-color: $white;
    }
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    margin-bottom: $global-margin;
    padding: 0;
    list-style: none;
  }

  .breadcrumb-item {
    padding-left: $global-padding;
  }
  .breadcrumb-item a {
    color: $font-color;
  }
  .breadcrumb-item:first-child {
    padding-left: 0;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    content: "|";
    color: $font-color;
    padding-right: $global-padding;
  }
}
