@import "./assets/sass/global.scss";

@font-face {
  font-family: "HouschkaHead";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/houschkahead/houschkahead-medium-web.eot"); /* IE9 Compat Modes */
  src: local("HouschkaHead"), local("HouschkaHead"), url("./assets/fonts/houschkahead/houschkahead-medium-web.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/houschkahead/houschkahead-medium-web.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./assets/fonts/houschkahead/houschkahead-medium-web.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/houschkahead/houschkahead-medium-web.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./assets/fonts/houschkahead/houschkahead-medium-web.svg#OpenSans") format("svg"); /* Legacy iOS */
}
@font-face {
  font-family: "HouschkaHead";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/houschkahead/houschkahead-light-web.eot"); /* IE9 Compat Modes */
  src: local("HouschkaHead"), local("HouschkaHead"), url("./assets/fonts/houschkahead/houschkahead-light-web.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/houschkahead/houschkahead-light-web.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./assets/fonts/houschkahead/houschkahead-light-web.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/houschkahead/houschkahead-light-web.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./assets/fonts/houschkahead/houschkahead-light-web.svg#OpenSans") format("svg"); /* Legacy iOS */
}
@font-face {
  font-family: "HouschkaHead";
  font-style: normal;
  font-weight: 600;
  src: url("./assets/fonts/houschkahead/houschkahead-demibold-web.eot"); /* IE9 Compat Modes */
  src: local("HouschkaHead"), local("HouschkaHead"), url("./assets/fonts/houschkahead/houschkahead-demibold-web.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/houschkahead/houschkahead-demibold-web.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./assets/fonts/houschkahead/houschkahead-demibold-web.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/houschkahead/houschkahead-demibold-web.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./assets/fonts/houschkahead/houschkahead-demibold-web.svg#OpenSans") format("svg"); /* Legacy iOS */
}
@font-face {
  font-family: "HouschkaHead";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/houschkahead/houschkahead-bold-web.eot"); /* IE9 Compat Modes */
  src: local("HouschkaHead"), local("HouschkaHead"), url("./assets/fonts/houschkahead/houschkahead-bold-web.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/houschkahead/houschkahead-bold-web.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./assets/fonts/houschkahead/houschkahead-bold-web.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/houschkahead/houschkahead-bold-web.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./assets/fonts/houschkahead/houschkahead-bold-web.svg#OpenSans") format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "Neutraface";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/neutraface/NeutrafaceText-Light.eot"); /* IE9 Compat Modes */
  src: local("Neutraface"), local("Neutraface"), url("./assets/fonts/neutraface/NeutrafaceText-Light.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/neutraface/NeutrafaceText-Light.woff2") format("woff2"), /* Super Modern Browsers */ url("./assets/fonts/neutraface/NeutrafaceText-Light.woff") format("woff"),
    /* Modern Browsers */ //url("./assets/fonts/neutraface/NeutrafaceText-Light.ttf") format("truetype");
    /* Safari, Android, iOS */ url("./assets/fonts/neutraface/NeutrafaceText-Light.svg#OpenSans") format("svg"); /* Legacy iOS */
}
@font-face {
  font-family: "Neutraface";
  font-style: normal;
  font-weight: 600;
  src: url("./assets/fonts/neutraface/NeutrafaceText-Demi.eot"); /* IE9 Compat Modes */
  src: local("Neutraface"), local("Neutraface"), url("./assets/fonts/neutraface/NeutrafaceText-Demi.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/neutraface/NeutrafaceText-Demi.woff2") format("woff2"), /* Super Modern Browsers */ url("./assets/fonts/neutraface/NeutrafaceText-Bold.woff") format("woff"),
    /* Modern Browsers */ //url("./assets/fonts/neutraface/NeutrafaceText-Bold.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./assets/fonts/neutraface/NeutrafaceText-Bold.svg#OpenSans") format("svg"); /* Legacy iOS */
}
@font-face {
  font-family: "Neutraface";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/neutraface/NeutrafaceText-Bold.eot"); /* IE9 Compat Modes */
  src: local("Neutraface"), local("Neutraface"), url("./assets/fonts/neutraface/NeutrafaceText-Bold.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/neutraface/NeutrafaceText-Bold.woff2") format("woff2"), /* Super Modern Browsers */ url("./assets/fonts/neutraface/NeutrafaceText-Bold.woff") format("woff"),
    /* Modern Browsers */ //url("./assets/fonts/neutraface/NeutrafaceText-Bold.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./assets/fonts/neutraface/NeutrafaceText-Bold.svg#OpenSans") format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "Archer";
  font-style: normal;
  font-weight: 400;
  src: local("Archer"), url("./assets/fonts/archer/Archer-Medium.otf"), url("./assets/fonts/archer/Archer-Medium.ttf"), url("./assets/fonts/archer/Archer-Medium.woff2") format("woff2"),
    url("./assets/fonts/archer/Archer-Medium.woff") format("woff");
}

@font-face {
  font-family: "Archer";
  font-style: normal;
  font-weight: 700;
  src: local("Archer"), url("./assets/fonts/archer/Archer-Bold.otf"), url("./assets/fonts/archer/Archer-Bold.ttf"), url("./assets/fonts/archer/Archer-Bold.woff2"), format("woff2"),
    url("./assets/fonts/archer/Archer-Bold.woff"), format("woff");
}

@font-face {
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 700;
  src: local("OpenSans"), url("./assets/fonts/opensans/OpenSans-Bold.ttf"), url("./assets/fonts/opensans/OpenSans-Bold.ttf"), url("./assets/fonts/opensans/OpenSans-Bold.ttf");
}

@font-face {
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 400;
  src: local("OpenSans"), url("./assets/fonts/opensans/OpenSans-Regular.ttf"), url("./assets/fonts/opensans/OpenSans-Regular.ttf"), url("./assets/fonts/opensans/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  src: local("Ubuntu"), url("./assets/fonts/ubuntu/Ubuntu-M.ttf"), url("./assets/fonts/ubuntu/Ubuntu-M.ttf"), url("./assets/fonts/ubuntu/Ubuntu-M.ttf");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto"), url("./assets/fonts/roboto/Roboto-Bold.ttf"), url("./assets/fonts/roboto/Roboto-Bold.ttf"), url("./assets/fonts/roboto/Roboto-Bold.ttf");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), url("./assets/fonts/roboto/Roboto-Regular.ttf"), url("./assets/fonts/roboto/Roboto-Regular.ttf"), url("./assets/fonts/roboto/Roboto-Regular.ttf");
}

@font-face {
  font-family: "NotoSerif";
  font-style: normal;
  font-weight: 700;
  src: local("NotoSerif"), url("./assets/fonts/noto-serif/NotoSerif-Bold.ttf"), url("./assets/fonts/noto-serif/NotoSerif-Bold.ttf"), url("./assets/fonts/noto-serif/NotoSerif-Bold.ttf");
}

@font-face {
  font-family: "NotoSans";
  font-style: normal;
  font-weight: 500;
  src: local("NotoSans"), url("./assets/fonts/noto-sans/NotoSans-Bold.ttf"), url("./assets/fonts/noto-sans/NotoSans-Bold.ttf"), url("./assets/fonts/noto-sans/NotoSans-Bold.ttf");
}

@font-face {
  font-family: "NotoSans";
  font-style: normal;
  font-weight: 400;
  src: local("NotoSans"), url("./assets/fonts/noto-sans/NotoSans-Regular.ttf"), url("./assets/fonts/noto-sans/NotoSans-Regular.ttf"), url("./assets/fonts/noto-sans/NotoSans-Regular.ttf");
}

@font-face {
  font-family: "DIN";
  font-style: normal;
  font-weight: 700;
  src: local("DIN"), url("./assets/fonts/din/PFDinTextCompPro-Bold.ttf"), url("./assets/fonts/din/PFDinTextCompPro-Bold.ttf"), url("./assets/fonts/din/PFDinTextCompPro-Bold.ttf");
}

@font-face {
  font-family: "CircularStd";
  font-style: normal;
  font-weight: 500;
  src: local("CircularStd"), url("./assets/fonts/circular/CircularStd-Book.otf"), url("./assets/fonts/circular/CircularStd-Book.otf"), url("./assets/fonts/circular/CircularStd-Book.otf");
}

@font-face {
  font-family: "CircularStd";
  font-style: normal;
  font-weight: 400;
  src: local("CircularStd"), url("./assets/fonts/circular/CircularStd-Medium.otf"), url("./assets/fonts/circular/CircularStd-Medium.otf"), url("./assets/fonts/circular/CircularStd-Medium.otf");
}

@font-face {
  font-family: "Colfax";
  font-style: normal;
  font-weight: 700;
  src: local("Colfax"), url("./assets/fonts/colfax/Colfax-Bold.otf"), url("./assets/fonts/colfax/Colfax-Bold.otf"), url("./assets/fonts/colfax/Colfax-Bold.otf");
}

@font-face {
  font-family: "Colfax";
  font-style: normal;
  font-weight: 400;
  src: local("Colfax"), url("./assets/fonts/colfax/Colfax-Regular.otf"), url("./assets/fonts/colfax/Colfax-Regular.otf"), url("./assets/fonts/colfax/Colfax-Regular.otf");
}

@font-face {
  font-family: "QuattrocentoSans";
  font-style: normal;
  font-weight: 700;
  src: local("QuattrocentoSans"), url("./assets/fonts/quattrocentoSans/QuattrocentoSans-Bold.ttf"), url("./assets/fonts/quattrocentoSans/QuattrocentoSans-Bold.ttf"),
    url("./assets/fonts/quattrocentoSans/QuattrocentoSans-Bold.ttf");
}

@font-face {
  font-family: "QuattrocentoSans";
  font-style: normal;
  font-weight: 700;
  src: local("QuattrocentoSans"), url("./assets/fonts/quattrocentoSans/QuattrocentoSans-Regular.ttf"), url("./assets/fonts/quattrocentoSans/QuattrocentoSans-Regular.ttf"),
    url("./assets/fonts/quattrocentoSans/QuattrocentoSans-Regular.ttf");
}

body {
  cursor: default;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#recruitment-connect-container {
  width: 100%;
  height: 100vh;
  font-family: var(--fontFamily);
}

// #root {
//   & > div:not(.adecco-ui, .tag-ds) {
//     height: 100% !important;
//   }
// }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
