/*visibility*/
.hidden {
  display: none !important;
}

.adecco-ui {
  /*bg colors*/
  .bg-primary {
    background-color: $default-primary-color !important;
    @include themes($themes) {
      background-color: themed("primary-color") !important;
    }
  }
  .bg-active {
    background-color: $active-primary-color !important;
    @include themes($themes) {
      background-color: themed("active-color") !important;
    }
  }
  .bg-error {
    background-color: $error-color !important;
  }
  .bg-success {
    background-color: $success-color !important;
  }
  .bg-white {
    background-color: #fff !important;
  }
  .bg-warning {
    background-color: $warning-color !important;
  }
  .bg-info {
    background-color: $blue !important;
  }
  .bg-check {
    background-color: $green !important;
  }
  &.bg-light-grey,
  .bg-light-grey {
    background-color: $light-grey !important;
    @include themes($themes) {
      background-color: $light-grey !important;
    }
  }
  .bg-input-grey {
    background-color: $input-background !important;
  }
  .bg-grey {
    background-color: $grey !important;
  }
  .bg-dark-grey {
    background-color: $dark-grey !important;
  }
  .bg-black {
    background-color: $black !important;
  }
  .bg-transparent {
    background-color: transparent !important;
  }
  .bg-adecco {
    background-repeat: no-repeat;
    background-size: 100% auto;
    min-height: calc(100vh - 85px);
  }
  /*font colors*/
  .color-font {
    color: $font-color !important;
  }

  .grey-font {
    color: $grey-color !important;
  }
  .color-primary {
    color: $default-primary-color !important;
    @include themes($themes) {
      color: themed("primary-color") !important;
    }
  }
  .color-active {
    color: $default-primary-color !important;
    @include themes($themes) {
      color: themed("active-color");
    }
  }
  .color-error {
    color: $error-color;
  }
  .color-success {
    color: $success-color;
  }
  .color-warning {
    color: $warning-color;
  }

  .color-grey {
    color: $light-grey !important;
    @include themes($themes) {
      color: $light-grey !important;
    }
  }
  .color-white {
    color: #fff !important;
  }

  /*font weight*/
  .bold {
    font-weight: bold;
  }
  /*float*/
  .fl {
    float: left !important;
  }
  .fr {
    float: right !important;
  }
  .clearfix {
    display: block;
    height: 1px;
    zoom: 1;
    width: 100%;
    clear: both;
    margin-bottom: -1px;
  }
  /*text align*/
  .tl {
    text-align: left !important;
  }
  .tr {
    text-align: right !important;
  }
  .tc {
    text-align: center !important;
  }
  /*list*/
  .list-inline {
    margin: 0;
    padding-left: 0;
    list-style: none;
  }
  .list-inline li {
    display: inline-block;
    margin: 0;
  }
  .no-list {
    list-style: none;
    margin-left: 0;
  }
  /*narrow content*/
  .narrow-content {
    max-width: $tablet;
    margin: 0 auto;
  }
  .visible {
    display: block;
  }
  /*effects*/
  .shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  /*radios*/
  .radius {
    border-radius: 10px 10px 10px 10px;
  }
  .no-border,
  &.no-border {
    border: none !important;
  }
  /*text*/
  .ellipsis {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;
  }
  .uppercase {
    text-transform: uppercase;
  }
  /*position*/
  .relative {
    position: relative !important;
  }
  .absolute {
    position: absolute !important;
  }
  .inner {
    margin: calc($global-margin/2);
    padding: calc($global-padding/2);
  }
  /*width*/
  .auto-width {
    width: auto !important;
  }
  .full-width {
    width: 100%;
    max-width: none;
  }
  /*overflow*/
  .overflow-hidden {
    overflow: hidden;
  }
  /*display*/
  .display-inlineblock {
    display: inline-block;
  }
  .display-block {
    display: block;
  }
  /*cursor*/
  .cursor-pointer {
    cursor: pointer;
  }
  .align-center {
    display: flex;
    align-items: center;
  }
  @media (min-width: $desktop) {
    .desktop-flex {
      display: flex !important;
    }
  }
  @media (min-width: $tablet) {
    .laptop-hidden {
      display: none !important;
    }
  }
  @media (max-width: $tablet) {
    .tablet-hidden {
      display: none !important;
    }
    .tablet-displayed {
      display: block !important;
    }
  }
  @media (max-width: $mobile) {
    .mobile-hidden {
      display: none !important;
    }
    .mobile-displayed {
      display: block !important;
    }
  }
}
