.rc-ui {
  textarea {
    border: 1.5px solid rgba(200, 199, 199, 0.5);
    border-radius: 5px;
    padding: 8px;
    resize: none;
    width: 100%;
  }
  .input-wrapper.error {
    input,
    textarea,
    select,
    .select-filter .inputs-group,
    .editor-container .editor-content {
      border-color: $red;
    }
    .-content-question .-option .-option-text {
      border-color: $red !important;
    }
    .input-error::before {
      margin-right: 0.5em;
      content: "\f06a";
      color: $red;
      font-weight: 900;
      font-family: "Font Awesome 5 Free", sans-serif;
    }
  }
}
