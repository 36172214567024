body {
  .adia-theme {
    --primaryColor: #003d3a;
    --headerColor: var(--primaryColor);
    --secondaryColor: #00a78d;
    --globalBorderRadius: 0px;
    --componentColor: var(--secondaryColor);
    --titleHomeProcessColor: var(--white);
    --tabSelectedColor: var(--mainColor);
    
    //Fonts colors
    --headerFontColor: var(--white);
    --breadcrumbFontColor: var(--primaryColor);
    --linkFontColor: var(--primaryColor);
    
    //Button colors
    --primaryButtonColor: #003d3a;
    --secondaryButtonColor: var(--white);
    --hoverButtonColor: #4a6f7d;
    --disableButtonColor: #d5dbdf;
    --focusButtonColor: var(--primaryButtonColor);

    //Active buttons
    --primaryButtonFontColor: var(--white);
    --primaryButtonBorderColor: var(--primaryButtonColor);

    --secondaryButtonFontColor: var(--primaryButtonColor);
    --secondaryButtonBorderColor: var(--primaryButtonColor);

    //Hover Buttons
    --primaryButtonHoverColor: var(--hoverButtonColor);
    --primaryButtonHoverFontColor: var(--white);
    --primaryButtonHoverBorderColor: var(--hoverButtonColor);

    --secondaryButtonHoverColor: var(--white);
    --secondaryButtonHoverFontColor: var(--hoverButtonColor);
    --secondaryButtonHoverBorderColor: var(--hoverButtonColor);

    //Disable Buttons
    --primaryButtonInactiveColor: var(--disableButtonColor);
    --primaryButtonInactiveFontColor: var(--white);
    --primaryButtonInactiveBorderColor: var(--disableButtonColor);

    --secondaryButtonInactiveColor: var(--white);
    --secondaryButtonInactiveFontColor: var(--disableButtonColor);
    --secondaryButtonInactiveBorderColor: var(--disableButtonColor);

    --mainColor: #003d3a;

    --dropdownBackgroundColor: var(--white);
    --dropdownElementHoverColor: #e2e2e2;
    --mainLogo: url("../images/adia-theme/common/logo.png");
    --mainLogoColored: url("../images/adia-theme/common/logo.png");
    --homeTopImage: url("../images/adia-theme/home/home-top.png");

    --buttonPrimaryActiveColor: var(--primaryButtonColor);
    --buttonPrimaryBorderColor: var(--primaryButtonColor);
    --buttonPrimaryActiveFontColor: var(--white);

    --buttonPrimaryHoverColor: var(--hoverButtonColor);
    --buttonPrimaryHoverBorderColor: var(--hoverButtonColor);
    --buttonPrimaryHoverFontColor: var(--white);

    --buttonPrimaryPressedColor: var(--primaryButtonColor);
    --buttonPrimaryPressedFontColor: var(--white);

    --buttonPrimaryInactiveColor: var(--disableButtonColor);
    --buttonPrimaryInactiveFontColor: var(--white);

    --buttonSecondaryActiveColor: var(--white);
    --buttonSecondaryBorderColor: var(--primaryButtonColor);
    --buttonSecondaryActiveFontColor: var(--primaryButtonColor);

    --buttonSecondaryHoverColor: var(--white);
    --buttonSecondaryHoverBorderColor: var(--hoverButtonColor);
    --buttonSecondaryHoverFontColor: var(--hoverButtonColor);

    --buttonSecondaryPressedColor: var(--primaryButtonColor);
    --buttonSecondaryPressedFontColor: var(--white);

    --buttonSecondaryInactiveColor: var(--white);
    --buttonSecondaryInactiveFontColor: var(--disableButtonColor);

    --buttonBlueActiveColor: #00b5f0;
    --buttonBlueActiveFontColor: var(--white);
    --buttonBlueHoverColor: #0096ef;
    --buttonBlueHoverFontColor: var(--white);
    --buttonBluePressedColor: #196fc9;
    --buttonBluePressedFontColor: var(--white);
    --helpButton: url("../images/adia-theme/candidate/help-button.png");
    --phaseCompleted: url("../images/adia-theme/candidate/phase-completed.png");
    --phaseFailed: url("../images/adia-theme/candidate/phase-failed.png");
    --dropdown: url("../images/adia-theme/common/dropdown.png");
    --candidatePublicBackgroundStart: #003d3a;
    --candidatePublicBackgroundEnd: #003d3a;
    --candidateDesktopArrowTop: url("../images/common/no-backg.png");
    --candidateDesktopArrowBottom: url("../images/common/no-backg.png");
    --candidateMobileArrowTop: url("../images/common/no-backg.png");
    --candidateMobileArrowBottom: url("../images/common/no-backg.png");
    --candidateLightArrowTop: url("../images/candidate-web/chevron.png");
    --candidateLightArrowBottom: url("../images/candidate-web/Group@3x-2.png");
    --candidateLightArrowInvert: url("../images/candidate-web/chevron-invert.png");
    --candidateDownloadPdf: url("../images/candidate-web/download-pdf.png");
    --adminServiceFeatureBackground: var(--gray100);
    --adminServiceFeatureBorder: var(--gray200);

    --fontColor: var(--white);
    --linkFontColor: #00a9e0;
    --black: #000000;
    --white: #ffffff;
    --gray100: #f0f0f0;
    --gray200: #d4d4d4;
    --gray300: #888;
    --gray400: #555;
    --errorColor: #e1001a;

    //Font Family
    --fontFamily: "Roboto";
    --fontFamilyUrl: "https://fonts.googleapis.com/css?family=Comfortaa:300,400,500,600,700&display=swap";
    --fontFamilyHeaders: "Ubuntu";
    --fontFamilyBody: "Roboto";
  }
}
