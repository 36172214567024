.spelling {
  &.insight-error {
    border-bottom: 1px dashed red;
    position: relative;
  }

  &.insight-error .spelling-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    background: $light-grey;
    display: none;
    padding: calc($global-padding/2);
    border: $global-border;
    padding-right: 20px;
  }
  &.insight-error .spelling-tooltip .close-icon {
    width: 10px;
    height: 10px;
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    line-height: 10px;
    cursor: pointer;
  }
}
.editor-content .spelling.insight-error:hover .spelling-tooltip {
  display: block;
}
