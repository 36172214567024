.adecco-ui {
  .inclusive-bar {
    display: flex;
    top: 0;
    z-index: 10;
    .inclusive-bar-fixed-fill {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      background: var(--gray100);

      .inclusive-bar-group {
        position: relative;
        padding: $global-padding 0;
        background: var(--gray100);
        padding-left: $global-padding;
        ~ .inclusive-bar-group {
          padding-left: 0;
        }
        &:last-child {
          padding-right: $global-padding;
        }

        .inclusive-button-dropdown {
          background-color: var(--white);
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 13em;
          height: 4.5em;
          padding: 0em $global-padding;
          border: $global-border;
          border-color: var(--gray100);
          &.-selected {
            border-color: var(--black);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
          }
          .-title-button-dropdown {
            font-weight: $font-weight-bold;
          }
          .-dropdown-icon {
            position: absolute;
            right: 8px;
            margin-top: 3px;
            width: $icon-size-l;
            height: $icon-size-l;
            background-size: $icon-size-l $icon-size-l;
            background-repeat: no-repeat;
            transform: rotate(90deg);
            background-image: url("../../../assets/images/candidate/next.png");
          }
        }
        .modal-dropdown {
          display: none;
        }
        // When open
        &.-open-dropdown {
          .-body-button-dropdown {
            font-weight: $font-weight-bold;
          }
          .-dropdown-icon {
            transform: rotate(270deg);
          }
          .modal-dropdown {
            position: absolute;
            z-index: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            min-width: 13em;
            border: $global-border;
            border-color: var(--gray100);
            border-radius: $border-radius;
            background-color: white;
            padding: $global-padding;
            margin-top: calc($global-margin / 4);
          }
          .modal-dropdown-option {
            display: flex;
            .-title-section-modal {
              padding-right: calc($global-padding / 4);
              i {
                padding-right: 2px;
              }
            }
            .-count-section-modal {
              margin-top: 2px;
              display: flex;
              font-weight: $font-weight-bold;
            }
            .-after-count {
              padding-left: calc($global-padding / 4);
            }
          }
          .-empty-sections-modal {
            font-style: italic;
          }
        }
      }
      .inclusive-total {
        background-color: var(--white);
        border: $global-border;
        border-color: var(--gray100);
        display: flex;
        align-items: center;
        padding: $global-padding 0rem;
        .inclusive-total-graph {
          font-weight: $font-weight-bold;
          padding: 0rem $global-padding;
        }
        .inclusive-total-text {
          font-weight: $font-weight-bold;
          padding-right: calc($global-padding * 3 / 2);
        }

        .inclusive-total-svg-line {
          stroke: var(--white);
          stroke-width: 8;
          stroke-linecap: round;
          stroke-dasharray: 176;
          transition: all 3s ease-in-out;
        }
      }
    }
  }
  @media (max-width: 991px) {
    .inclusive-bar {
      .inclusive-bar-fixed-fill {
        flex-direction: column;
        .inclusive-bar-group {
          padding: 0;
          padding-left: 0;
          padding-right: 0;
          &:last-child {
            padding-right: 0;
          }
          .inclusive-button-dropdown {
            width: 100%;
            padding: 0em calc($global-padding / 2);
          }
          &.-open-dropdown {
            .modal-dropdown {
              min-width: 100%;
              margin-left: calc($global-margin / 2);
            }
          }
        }
        .inclusive-total {
          padding: 0;
          .inclusive-total-graph {
            padding: 0rem calc($global-padding / 2);
          }
          .inclusive-total-text {
            padding-right: calc($global-padding / 2);
          }
        }
      }
    }
  }
  /* Extend container to adjust width*/
  @media (min-width: 576px) {
    .inclusive-bar.fixed {
      max-width: 540px;
    }
  }
  @media (min-width: 768px) {
    .inclusive-bar.fixed {
      max-width: 720px;
    }
  }
  @media (min-width: 992px) {
    .inclusive-bar.fixed {
      max-width: 960px;
      // right: 17px; // Avoid overlapscroll
      // width: calc(100vw - var(--width-sidebar) - 17px);
      position: fixed;
      // top: var(--breadcrumb-height);
      top: calc(var(--breadcrumb-height) + 10 * 8px); // 8px is --unit
      padding-right: 30px;
    }
  }
  @media (min-width: 1200px) {
    .inclusive-bar.fixed {
      width: calc(100vw - 100px - 1rem); // 100 of lateral menu - 1rem-> padding and borders
      max-width: 1140px;
    }
  }
}
