.tag-ds {
  .pagination {
    padding: 0;
    text-decoration: none;
    display: flex;
  }
  .pagination .btn {
    margin: 0;
    background-color: var(--white);
    height: 25px;
    width: 25px;
    padding: 0;
    border-radius: 50%;
    font-size: 12px;
    color: var(--text-02);
    border: none;
    min-width: auto;

    &:active,
    &:focus {
      box-shadow: none;
      background-color: var(--white);
      border-color: var(--borders-dividers-01);
    }
    &:hover {
      border-color: var(--borders-dividers-01);
      background-color: var(--backgrounds-03);
    }
    &.active {
      background-color: var(--primaryButtonColor);
      color: var(--white);
      opacity: 1;
      &:hover {
        background-color: var(--primaryButtonColor);
      }
    }
    &.separator {
      opacity: 1;
    }
  }

  .pagination ul {
    display: flex;
    border-radius: 0px !important;
    list-style: none;
  }

  @media (max-width: $tablet) {
    .pagination {
      display: block;
    }
    .pagination ul {
      text-align: center;
      width: 100%;
    }
  }
}
