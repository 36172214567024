#recruiter-copilot {
  --bubble-size: calc(10 * var(--unit));
  --copilot-right-position: calc(10 * var(--unit));
  #copilot-dialog {
    width: calc(60 * var(--unit));
    position: absolute;
    right: var(--copilot-right-position);
    top: calc(calc(17 * var(--unit)) + var(--bubble-size));
    -webkit-animation: scale-up-tr 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-tr 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    overflow: visible;
    .copilot-action {
      cursor: pointer;
    }
    h5 {
      width: 90%;
    }
    .input-wrapper {
      margin-bottom: 0;
      width: calc(48 * var(--unit));
    }
    .button-card-icon {
      position: absolute;
      right: var(--unit);
      top: var(--unit);
    }
  }
  #copilot-bubble {
    cursor: pointer;
    z-index: 5;
    height: var(--bubble-size);
    width: var(--bubble-size);
    max-width: var(--bubble-size);
    min-width: var(--bubble-size);
    max-height: var(--bubble-size);
    min-height: var(--bubble-size);
    position: absolute;
    right: var(--copilot-right-position);
    top: calc(15 * var(--unit));
    border: none;
    border-radius: 50%;
    background: radial-gradient(
      circle at 75% 30%,
      white 5px,
      aqua 8%,
      darkblue 60%,
      aqua 100%
    );
    opacity: 0.8;
    box-shadow: 
        inset 0 0 calc(var(--bubble-size)/10) #fff,
        inset calc(var(--bubble-size)/20) 0 calc(var(--bubble-size)/4.35) #eaf5fc,
        inset calc(var(--bubble-size)/2.27) 0px calc(var(--bubble-size)/3.33) #c2d8fe,
        inset calc(-1 * var(--bubble-size)/10) calc(-1 * var(--bubble-size)/3.33) calc(var(--bubble-size)/2) #fde9ea,
        inset 0 calc(var(--bubble-size)/4) calc(var(--bubble-size)/1.43) #fde9ea, 
        0 0 calc(var(--bubble-size)/2.22) #fff;
    .material-icons {
        transform: scale(1.2);
    }
  }
  .shine-class {
    -webkit-animation: shine 1.5s both;
    animation: shine 1.5s both;
    animation-iteration-count: infinite;
  }
}

/**
 * ----------------------------------------
 * animation scale-up-tr
 * ----------------------------------------
 */
 @-webkit-keyframes scale-up-tr {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}
@keyframes scale-up-tr {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}

/**
 * ----------------------------------------
 * animation shine
 * ----------------------------------------
 */
 @-webkit-keyframes shine {
  0% {
    box-shadow: 
        inset 0 0 calc(var(--bubble-size)/10) #fff,
        inset calc(var(--bubble-size)/20) 0 calc(var(--bubble-size)/4.35) #eaf5fc,
        inset calc(var(--bubble-size)/2.27) 0px calc(var(--bubble-size)/3.33) #c2d8fe,
        inset calc(-1 * var(--bubble-size)/10) calc(-1 * var(--bubble-size)/3.33) calc(var(--bubble-size)/2) #fde9ea,
        inset 0 calc(var(--bubble-size)/4) calc(var(--bubble-size)/1.43) #fde9ea, 
        0 0 calc(var(--bubble-size)/2.22) #fff;
  }
  25% {
    box-shadow: 
        inset 0 0 calc(var(--bubble-size)/10) #fff,
        inset calc(var(--bubble-size)/20) 0 calc(var(--bubble-size)/4.35) #eafbfc,
        inset calc(var(--bubble-size)/2.27) 0px calc(var(--bubble-size)/3.33) #f7c2fe,
        inset calc(-1 * var(--bubble-size)/10) calc(-1 * var(--bubble-size)/3.33) calc(var(--bubble-size)/2) #e9f2fd,
        inset 0 calc(var(--bubble-size)/4) calc(var(--bubble-size)/1.43) #e9fdf8, 
        0 0 calc(var(--bubble-size)/2.22) #ffffff;
  }
  50% {
    box-shadow: 
        inset 0 0 calc(var(--bubble-size)/10) #fff,
        inset calc(var(--bubble-size)/20) 0 calc(var(--bubble-size)/4.35) #eaeffc,
        inset calc(var(--bubble-size)/2.27) 0px calc(var(--bubble-size)/3.33) #cdc2fe,
        inset calc(-1 * var(--bubble-size)/10) calc(-1 * var(--bubble-size)/3.33) calc(var(--bubble-size)/2) #fde9f4,
        inset 0 calc(var(--bubble-size)/4) calc(var(--bubble-size)/1.43) #fde9f9, 
        0 0 calc(var(--bubble-size)/2.22) #ffffff;
  }
  75% {
    box-shadow: 
        inset 0 0 calc(var(--bubble-size)/10) #fff,
        inset calc(var(--bubble-size)/20) 0 calc(var(--bubble-size)/4.35) #eafbfc,
        inset calc(var(--bubble-size)/2.27) 0px calc(var(--bubble-size)/3.33) #f7c2fe,
        inset calc(-1 * var(--bubble-size)/10) calc(-1 * var(--bubble-size)/3.33) calc(var(--bubble-size)/2) #e9f2fd,
        inset 0 calc(var(--bubble-size)/4) calc(var(--bubble-size)/1.43) #e9fdf8, 
        0 0 calc(var(--bubble-size)/2.22) #ffffff;
  }
  100% {
    box-shadow: 
        inset 0 0 calc(var(--bubble-size)/10) #fff,
        inset calc(var(--bubble-size)/20) 0 calc(var(--bubble-size)/4.35) #eaf5fc,
        inset calc(var(--bubble-size)/2.27) 0px calc(var(--bubble-size)/3.33) #c2d8fe,
        inset calc(-1 * var(--bubble-size)/10) calc(-1 * var(--bubble-size)/3.33) calc(var(--bubble-size)/2) #fde9ea,
        inset 0 calc(var(--bubble-size)/4) calc(var(--bubble-size)/1.43) #fde9ea, 
        0 0 calc(var(--bubble-size)/2.22) #fff;
  }
}
@keyframes shine {
  0% {
    box-shadow: 
        inset 0 0 calc(var(--bubble-size)/10) #fff,
        inset calc(var(--bubble-size)/20) 0 calc(var(--bubble-size)/4.35) #eaf5fc,
        inset calc(var(--bubble-size)/2.27) 0px calc(var(--bubble-size)/3.33) #c2d8fe,
        inset calc(-1 * var(--bubble-size)/10) calc(-1 * var(--bubble-size)/3.33) calc(var(--bubble-size)/2) #fde9ea,
        inset 0 calc(var(--bubble-size)/4) calc(var(--bubble-size)/1.43) #fde9ea, 
        0 0 calc(var(--bubble-size)/2.22) #fff;
  }
  25% {
    box-shadow: 
        inset 0 0 calc(var(--bubble-size)/10) #fff,
        inset calc(var(--bubble-size)/20) 0 calc(var(--bubble-size)/4.35) #eafbfc,
        inset calc(var(--bubble-size)/2.27) 0px calc(var(--bubble-size)/3.33) #f7c2fe,
        inset calc(-1 * var(--bubble-size)/10) calc(-1 * var(--bubble-size)/3.33) calc(var(--bubble-size)/2) #e9f2fd,
        inset 0 calc(var(--bubble-size)/4) calc(var(--bubble-size)/1.43) #e9fdf8, 
        0 0 calc(var(--bubble-size)/2.22) #ffffff;
  }
  50% {
    box-shadow: 
        inset 0 0 calc(var(--bubble-size)/10) #fff,
        inset calc(var(--bubble-size)/20) 0 calc(var(--bubble-size)/4.35) #eaeffc,
        inset calc(var(--bubble-size)/2.27) 0px calc(var(--bubble-size)/3.33) #cdc2fe,
        inset calc(-1 * var(--bubble-size)/10) calc(-1 * var(--bubble-size)/3.33) calc(var(--bubble-size)/2) #fde9f4,
        inset 0 calc(var(--bubble-size)/4) calc(var(--bubble-size)/1.43) #fde9f9, 
        0 0 calc(var(--bubble-size)/2.22) #ffffff;
  }
  75% {
    box-shadow: 
        inset 0 0 calc(var(--bubble-size)/10) #fff,
        inset calc(var(--bubble-size)/20) 0 calc(var(--bubble-size)/4.35) #eafbfc,
        inset calc(var(--bubble-size)/2.27) 0px calc(var(--bubble-size)/3.33) #f7c2fe,
        inset calc(-1 * var(--bubble-size)/10) calc(-1 * var(--bubble-size)/3.33) calc(var(--bubble-size)/2) #e9f2fd,
        inset 0 calc(var(--bubble-size)/4) calc(var(--bubble-size)/1.43) #e9fdf8, 
        0 0 calc(var(--bubble-size)/2.22) #ffffff;
  }
  100% {
    box-shadow: 
        inset 0 0 calc(var(--bubble-size)/10) #fff,
        inset calc(var(--bubble-size)/20) 0 calc(var(--bubble-size)/4.35) #eaf5fc,
        inset calc(var(--bubble-size)/2.27) 0px calc(var(--bubble-size)/3.33) #c2d8fe,
        inset calc(-1 * var(--bubble-size)/10) calc(-1 * var(--bubble-size)/3.33) calc(var(--bubble-size)/2) #fde9ea,
        inset 0 calc(var(--bubble-size)/4) calc(var(--bubble-size)/1.43) #fde9ea, 
        0 0 calc(var(--bubble-size)/2.22) #fff;
  }
}

.ai-generated {
  animation: shine-shadow 2s normal;
}

/**
 * ----------------------------------------
 * animation shine-shadow
 * ----------------------------------------
 */
 @-webkit-keyframes shine-shadow {
  50% {
    -webkit-box-shadow: 0px 0px 15px 5px #17a3b883;
            box-shadow: 0px 0px 15px 5px #17a3b883;
  }
}
@keyframes shine-shadow {
  50% {
    -webkit-box-shadow: 0px 0px 15px 5px #17a3b883;
            box-shadow: 0px 0px 15px 5px #17a3b883;
  }
}
