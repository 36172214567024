.adecco-ui {
  table {
    border-collapse: collapse !important;
    border-spacing: 0 !important;
    margin: 0 0 $global-margin * 3 0 !important;
    width: 100% !important;

    max-width: 100%;
  }

  /* 

.reorder-trigger {
  display: none;
  width: 30px;
  height: 40px;
  position: absolute;
  top: 50%;
  right: 25px;
  z-index: 1;
  text-align: center;
  line-height: 40px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.reorder-trigger i {
  pointer-events: none;
}

th:hover .reorder-trigger {
  display: blocK;
}

#cursorText a {
  color: black !important;
}
 */

  /* .table thead th {
    position: relative;
    user-select: none;
    vertical-align: middle;
  } */

  table th {
    position: relative;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    height: auto;
    max-width: 200px;
    width: auto !important;
    border-top: 2px solid $light-grey !important;
    background-color: $light-grey;
    padding: $global-padding /* * 1.5 */;
    text-align: center;
    @include themes($themes) {
      border-top: 2px solid $light-grey !important;
      background-color: 2px solid $light-grey;
    }
  }

  table th .resize {
    position: absolute;
    top: 0;
    right: 0;
    width: 5px;
    cursor: col-resize;
    height: 100%;
    border-right-style: solid;
    border-right-color: #d3d3d3;
    border-right-width: 1.5px;
  }
  table th:last-child .resize {
    display: none;
  }

  th p {
    display: inline-flex;
    margin: calc($global-margin/2);
    font-size: $small-font-size;
    font-weight: 700;
  }

  th .sortable-icon {
    cursor: pointer;
    position: relative;
    margin: calc($global-margin/2);
    width: 10px;
    height: 15px;
    pointer-events: none;
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
  }

  th .sortable-icon:before {
    content: "\f0dd";
    opacity: 0.3;
    position: absolute;
    top: 0;
  }

  th .sortable-icon:after {
    content: "\f0de";
    opacity: 0.3;
  }

  th.desc .sortable-icon:before {
    opacity: 1;
  }

  th.asc .sortable-icon:after {
    opacity: 1;
  }

  .results .list-group {
    padding: 0;
    max-height: 200px;
    overflow-y: auto;
  }

  table tr {
    border-top: 2px solid $light-grey !important;
    background-color: $white;
    @include themes($themes) {
      border-top: 2px solid $light-grey !important;
      background-color: $white;
    }
  }

  table td {
    vertical-align: top;
    height: auto;
    width: auto !important;

    font-size: $small-font-size;
    padding: $global-padding;

    // white-space: nowrap;
    // overflow-x: hidden;
    // text-overflow: ellipsis;
  }

  tbody {
    border-right: 2px solid $light-grey !important;
    border-left: 2px solid $light-grey !important;
    border-bottom: 2px solid $light-grey !important;
  }

  table td.ellipsis {
    display: table-cell;
  }

  .table-responsive {
    overflow-x: auto;
  }

  .table-responsive table {
    max-width: none;
  }

  .search-bar-wrapper .form-control {
    border-radius: 0;
  }

  #cursorText {
    position: absolute;
    border: 1px solid #dee2e6;
  }

  .form-inline .form-check {
    justify-content: inherit;
  }

  .no-data-found {
    padding: 10px;
  }

  table.striped-table {
    tr:nth-child(even) {
      background-color: $grey;
    }
  }

  table.dark-table {
    tr {
      background-color: $dark-grey;
      color: white;
    }
  }

  .option-link {
    cursor: pointer;
    color: black;
  }
  .ellipsis-icon:after {
    content: "\f141";
    font-family: "Font Awesome 5 Free", sans-serif;
    color: $black;
    font-weight: 900;
  }
  .repost-icon:before {
    content: "\f017";
    font-family: "Font Awesome 5 Free", sans-serif;
    color: $black;
    margin-right: $global-margin;
  }
  .edit-icon:before {
    content: "\f044";
    font-family: "Font Awesome 5 Free", sans-serif;
    color: $black;
    margin-right: $global-margin;
  }
  .edit-icon-input:before {
    content: "\f044";
    font-family: "Font Awesome 5 Free", sans-serif;
    color: $black;
    margin-left: $global-margin;
    font-size: 20px;
  }
  .save-icon-input:before {
    content: "\f0c7";
    font-family: "Font Awesome 5 Free", sans-serif;
    color: $black;
    margin-left: $global-margin;
    font-size: 25px;
  }
  .duplicate-icon:before {
    content: "\f0c5";
    font-size: 15px;
    font-family: "Font Awesome 5 Free", sans-serif;
    color: $black;
    margin-right: $global-margin;
  }
  .view-icon:before {
    content: "\f06e";
    font-size: 15px;
    font-family: "Font Awesome 5 Free", sans-serif;
    color: $black;
    margin-right: $global-margin;
  }
  .pause-icon:before {
    content: "\f28b";
    font-size: 16px;
    font-family: "Font Awesome 5 Free", sans-serif;
    color: $black;
    margin-right: $global-margin;
  }
  .print-icon:before {
    content: "\f02f";
    font-size: 14px;
    font-family: "Font Awesome 5 Free", sans-serif;
    color: $black;
    font-weight: 600;
    margin-right: $global-margin;
  }
  .envelope-icon:before {
    content: "\f0e0";
    font-size: 15px;
    font-family: "Font Awesome 5 Free", sans-serif;
    color: $black;
    margin-right: $global-margin;
  }
  .retry-icon span {
    font-size: 20px;
  }
  .delete-icon:before {
    content: "\f2ed";
    font-size: 15px;
    padding-left: 0.1rem;
    font-family: "Font Awesome 5 Free", sans-serif;
    color: $black;
    margin-right: $global-margin;
  }
  .close-icon:before {
    content: "\f057";
    font-size: 15px;
    font-family: "Font Awesome 5 Free", sans-serif;
    color: $black;
    margin-right: $global-margin;
  }
  .copy-icon:before {
    content: "\f0c5";
    font-size: 11px;
    background-color: $grey;
    padding: 0.4rem;
    border-radius: 50%;
    font-family: "Font Awesome 5 Free", sans-serif;
    color: $black;
  }
  .link-icon:before {
    content: "\f360";
    font-size: 15px;
    font-weight: bold;
    background-color: $grey;
    padding: 0.4rem;
    border-radius: 50%;
    font-family: "Font Awesome 5 Free", sans-serif;
    color: $black;
    margin-left: 1rem;
  }

  .rc-style {
    table td,
    table th {
      font-family: var(--fontFamily);
      border: none !important;
      background-color: transparent;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
    }
    .table {
      border: none !important;
    }
    .table thead th {
      background-color: none;
      font-weight: 700;
      color: gray;
    }
    .table tbody tr {
      box-shadow: 2px 2px 4px 2px #80808057;
      margin-bottom: 10px;
      border-radius: 3px;
    }
    .table td {
      padding: 15px;
    }
  }

  .proactive-sourcing-results {
    .filters-container .solid-grey-box {
      background-color: $light-grey;
      padding: 12px;
      & .search-icon {
        float: right;
        margin-right: 20px;
        margin-top: -35px;
        position: relative;
        z-index: 2;
        color: var(--gray);
      }
    }

    .border-line-top > td:first-child {
      border-top: 1px solid $grey !important;
    }

    .table-view-ref > table {
      border-collapse: none !important;
      & tr td {
        border-top: 2px solid white !important;
      }
      & tr td:last-child {
        border-right: 0 !important;
      }
    }

    table td {
      border-right: 1px solid $grey !important;
    }

    table th .info-circle {
      margin: calc($global-margin/2);
      width: 8px;
      height: 10px;
    }

    table th .sortable-th {
      position: relative;
    }

    .excell-button {
      width: 100%;
      color: $black;
      background-color: $white;
      border: 1px solid #000000;
      border-radius: 5px;
      &:hover {
        background: $white !important;
      }
      &:focus {
        background: $white !important;
      }
    }
  }
}

th .adeccoui-dropdown {
  position: relative;
  margin: calc($global-margin/2);
  display: inline-flex;
}

th .adeccoui-dropdown .filter-icon {
  background-image: url("../../../assets/images/candidate-sourcing/vector.svg");
  background-repeat: no-repeat;
  position: relative;
  width: 15px;
  height: 10px;
  /* -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); */
  display: inline;
}
