#cammio-reports {
    padding: calc(4 * var(--unit));

    .report-alert {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    select {
        padding-bottom: 10px;
        padding-top: 11px
    }

    .-disable {
        background-color: var(--gray100);
        color: var(--gray300);
    }

    .modal-data-count-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .material-icons {
            font-family: "Material Icons Outlined";
            font-size: 20px !important;
        }
    }

    .header-table {
        display: flex;
        justify-content: space-between;
    }

    .modal-data-count-parent {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .modal-data-count {
        display: flex;
        flex: 1;
        background: #FFFFFF;
        box-shadow: 6px 4px 24px rgba(28, 48, 75, 0.08);
        border-radius: 8px;
        align-items: center;
        text-align: center;
        padding: 20px
    }

    .data-count {
        padding-top: 2px;
    }
}