.adeccoui-dropdown {
  position: relative;
}
.adeccoui-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  display: none;
  float: left;
  min-width: 120px;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 0;
}
.adeccoui-dropdown-menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.adeccoui-dropdown-btn {
  cursor: pointer;
}
.adeccoui-dropdown-item {
  padding: 0;
  margin: 0;
  position: relative;
}
.adeccoui-dropdown-item:active {
  background-color: $light-grey;
}
.adeccoui-dropdown-item:last-child {
  border-bottom: none;
  margin: 0;
}
.adeccoui-dropdown-item a,
.adeccoui-dropdown-item > span {
  padding: $global-padding;
  display: block;
  text-decoration: none;
  color: $font-color;
  font-size: $small-font-size;
  cursor: pointer;
}
.adeccoui-dropdown-icon {
  margin-left: $global-margin;
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  &.revert {
    transform: rotate(180deg);
  }
}
.adeccoui-dropdown-icon::before {
  content: "\f106";
}

.adeccoui-dropdown-menu .adeccoui-dropdown-filter {
  min-width: 230px;
  margin-bottom: 0;
}
.adeccoui-dropdown-menu .adeccoui-dropdown-filter .select-filter {
  padding: $global-padding * 1.5;
}
.adeccoui-dropdown-menu .adeccoui-dropdown-filter .inputs-group {
  border: $global-border;
  border-radius: $border-radius;
}

.adeccoui-dropdown-menu .adeccoui-dropdown-filter .select-filter input {
  height: 31px;
}
.adeccoui-dropdown-menu .select-filter-container .input-group-item:last-child {
  padding: calc($global-padding / 2);
}

.adeccoui-dropdown-menu .select-filter-option {
  padding: 2.5px 5px;
  margin-top: 2.5px;
}
.adeccoui-dropdown-menu .select-bottom-container {
  margin-top: 0 !important;
}

/***************************/
.adeccoui-dropdown {
  width: 100%;
  position: relative;
  display: inline-block;
}

.adeccoui-dropdown-button {
  cursor: pointer;
}

.adeccoui-dropdown-button_disabled {
  pointer-events: none;
}

/**************/
.adeccoui-dropdown-menu .adeccoui-dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
}
.adeccoui-dropdown-menu .adeccoui-dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  margin-left: calc(4 * var(--unit));
  margin-top: calc(-1 * var(--unit));
}
.adeccoui-dropdown-menu .adeccoui-dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.adeccoui-dropdown-item.no-wrap,
.no-wrap .adeccoui-dropdown-item {
  white-space: nowrap;
}
.retry-icon-span {
  font-size: 20px;
  padding-right: 7px;
}
