@charset "utf-8";
@import "~@adeccoux/tag-ds/index.css";
@import "fa/css/all.css";
@import "_mixings.scss";
@import "_variables";
@import "_themes.scss";
@import "_fonts";
@import "_global";
@import "_hacks";
@import "_animations";
@import "_helpers";
@import "_helpers-ui";
@import "_app";
@import "_candidate-sourcing";
@import "_candidate-sourcing-ui";
@import "recluiment-form-ui";
@import "shared/_accordion";
@import "shared/_breadcrumb";
@import "shared/_calendar";
@import "shared/_card";
@import "shared/file-drop";
@import "shared/_forms";
@import "shared/_forms-ui";
@import "shared/table";
@import "shared/table-ui";
@import "shared/tooltip";
@import "shared/_modal";
@import "shared/_modal-ui";
@import "shared/_notifications";
@import "shared/_messages";
@import "shared/_messagesUI";
@import "shared/_pagination_DEPRECATED";
@import "shared/_pagination";
@import "shared/_select-filter";
@import "shared/_wysiwyg";
@import "shared/_wysiwygUI";
@import "shared/_inclusive";
@import "shared/_dropdown";
@import "shared/_tags";
@import "shared/_tags-ui";
@import "shared/_tableConfiguration";
@import "_spelling";
@import "_insight";
@import "shared/_results_DEPRECATED";
@import "shared/_results";
@import "shared/date-picker-component";
@import "_admin";
@import "_newDS-overrides";

.stepper-step-container:last-child .stepper-item-content::after {
  display: none;
}
