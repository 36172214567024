$default-primary-color: #e1001a !default;
$active-primary-color: #bf0d3e !default;

$white: #fff !default;
$light-grey: #f1f1f1 !default;
$grey: #c3c3c3 !default;
$dark-grey: #818181;
$black: #000 !default;

$blue: #0092e0 !default;
$red: #d43431 !default;
$yellow: #df9300 !default;
$light-yellow: #fceecc !default;
$green: #2d7d19 !default;
$warning-color: $yellow !default;
$success-color: $green !default;
$default-color: $dark-grey !default;
$error-color: $red !default;
$info-color: $blue !default;

$font-color: $black !default;
$grey-color: $dark-grey !default;
$link-color: #0092e3 !default;
$input-background: #fafafa !default;

/*margins and paddings*/
$global-padding: 10px !default;
$global-margin: 10px !default;

/*border*/
$global-border-size: 1px;
$global-border: $global-border-size solid $dark-grey !default;
$light-border: $global-border-size solid $light-grey;
$border-radius: 5px;

/*media queries breakpoints*/
$desktop: 1280px !default;
$laptop: 1024px !default;
$tablet: 960px !default;
$mobile: 640px !default;

/*fonts*/
$nunito-font-family: "Nunito", sans-serif !default;

$global-font-size: 14px !default;
$global-line-height: 18px !default;
$font-weight-bold: 600;

$small-font-size: 13px !default;

/*disable*/
$disabled-input: #fafafa;
$enabled-input: #000000;

/*icon*/
$icon-size: 16px;
$icon-size-l: 20px;

/*animation*/
$transition: 0.3s;

/*size*/
$wsysiwyg-info-size: 50px;
