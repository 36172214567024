.adecco-ui {
  .tag-button {
    @extend .btn;
    @extend .small;
    background: $light-grey;
    @include themes($themes) {
      background: $light-grey;
    }
    color: $font-color;
  }
  .tag-button.btn {
    background: $light-grey;
    @include themes($themes) {
      background: $light-grey;
    }
  }

  .tag-button button {
    margin: $global-padding * -1 $global-padding * -1 $global-padding * -1 calc($global-padding/2) !important;
    padding: calc($global-padding/2) !important;
    border-radius: 0 $border-radius $border-radius 0;
    outline: none !important;
  }
  button.tag-button:hover,
  input[type="button"].tag-button:hover,
  a.tag-button:hover,
  button.tag-button:focus,
  input[type="button"].tag-button:focus,
  a.tag-button:focus {
    button {
      @extend %btn-active;
    }
  }

  .pill {
    @extend .tag-button;
    min-width: 75px;
    display: inline-block;
    color: white;
    font-weight: 600;
    padding: 0px 10px;
    text-align: center;
    margin: 0;
    border-radius: 10px;
  }
  .White {
    background-color: $white;
    border: 0.5px solid $font-color;
    color: $font-color;
    @include themes($themes) {
      background-color: $white;
    }
  }

  .Draft,
  .draft {
    background-color: $warning-color;
    @include themes($themes) {
      background-color: $warning-color;
    }
  }
  .Schedule,
  .Scheduled,
  .scheduled,
  .schedule {
    background-color: $info-color;
    @include themes($themes) {
      background-color: $info-color;
    }
  }
  .pending,
  .Pending,
  .updating,
  .Updating,
  .deleting,
  .Deleting {
    color: $black;
    background-color: $light-yellow;
    @include themes($themes) {
      color: $black;
      background-color: $light-yellow;
    }
  }
  .online,
  .Online,
  .published,
  .Published,
  .running,
  .Running,
  .open,
  .Open {
    background-color: $success-color;
    @include themes($themes) {
      background-color: $success-color;
    }
  }
  .offline,
  .Offline,
  .paused,
  .Paused,
  .failed,
  .Failed,
  .notdeleted,
  .Notdeleted,
  .NotDeleted,
  .notupdated,
  .Notupdated,
  .NotUpdated {
    background-color: $error-color;
    @include themes($themes) {
      background-color: $error-color;
    }
  }
  .closing,
  .Closing {
    background-color: $grey;
    @include themes($themes) {
      background-color: $grey;
    }
  }
  .ended,
  .Ended,
  .closed,
  .Closed {
    background-color: $default-color;
    @include themes($themes) {
      background-color: $default-color;
    }
  }
  .archived,
  .Archived {
    background-color: $black;
    @include themes($themes) {
      background-color: $black;
    }
  }
}
