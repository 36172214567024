.tag-ds {
  .editor-container {
    height: auto;
    padding: 0;
    border: none;
    position: relative;
    .editor-container-child:last-child {
      &.editor-content {
        border-bottom: 1px solid var(--borders-dividers-interactive-on-light-enabled);
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
    }
    .editor-container-child:focus-visible {
      &.editor-content {
        border-bottom: 1px solid var(--borders-dividers-interactive-on-light-enabled);
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
    }
  }
  .editor-content {
    background-color: $white;
    @include themes($themes) {
      background-color: $white;
    }
    height: 190px;
    overflow: auto;
    padding: $global-padding;
    padding-bottom: 2em;
    border: 1px solid var(--borders-dividers-interactive-on-light-enabled);
    border-top: none;
    border-bottom: none;
  }
  .editor-content p {
    margin-bottom: var(--unit);
  }

  .empty-border {
    border-top: $global-border;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  .editor-toolbar {
    background-color: var(--backgrounds-02);
    padding: $global-padding;
    border: 1px solid var(--borders-dividers-interactive-on-light-enabled);
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    border-bottom: none;
  }

  .editor-commands > code > button {
    margin: 0 $global-margin 0 0;
    background: var(--backgrounds-02);
    border-color: var(--borders-dividers-01);
  }
  .editor-commands > code > button:hover {
    background: var(--backgrounds-03);
    border-color: var(--borders-dividers-01);
  }
  .editor-commands-button_selected {
    border: 1px solid var(--borders-dividers-buttons-pressed);
    border-color: var(--borders-dividers-buttons-pressed);
    background-color: var(--backgrounds-01);
  }

  .editor-commands .material-icons {
    color: var(--icons-01);
  }

  .editor-commands > code {
    display: flex;
  }

  .editor-dropdown-container {
    display: inline-block;
  }
  .editor-dropdown-container .editor-dropdown {
    position: absolute;
    display: none;
    margin: 0 1px;
  }
  .editor-dropdown-container:hover .editor-dropdown {
    display: block;
    background: $light-grey;
    @include themes($themes) {
      background: $light-grey;
    }
    padding: $global-padding;
  }

  .editor-btn-tooltip > .tooltip-item > button {
    margin: 0;
    border-radius: 0%;
  }

  .td-table-picker {
    margin: 0;
    padding: 0;
    height: 10px;
    width: 10px !important;
    border: $global-border !important;
  }
  .td-table-picker-selected {
    @include themes($themes) {
      background-color: themed("active-color");
    }
  }

  .btn-table-picker {
    margin: 0;
    padding: 8px;
    background-color: transparent;
  }

  .btn-color {
    border: $global-border;
    margin-right: calc($global-margin/2);
    padding: $global-padding;
  }

  .btn-color:hover {
    filter: brightness(110%);
  }

  .btn-headering {
    padding: $global-padding;
    background: transparent;
    @include themes($themes) {
      color: themed("primary-color");
      border: 1px solid themed("primary-color");
    }

    margin-bottom: 3px;
  }
  .btn-headering:hover {
    @include themes($themes) {
      background: themed("primary-color");
    }
    color: $white;
    @include themes($themes) {
      color: $white;
    }
  }

  #editor blockquote {
    margin: 0 0 0 40px !important;
    border: none !important;
    padding: 0px !important;
  }

  .editor-inclusive {
    display: flex;
    align-items: flex-start;
    background: $white;
    .editor-inclusive-icon {
      display: flex;
      width: 40px;
      margin-right: $global-margin;
      img {
        max-width: none;
      }
    }

    .editor-inclusive-text {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      min-height: $wsysiwyg-info-size;
      background: $light-yellow;
      border: $global-border;
      border-color: $yellow;
      border-bottom-left-radius: $border-radius;
      .editor-inclusive-main {
        display: flex;
        flex-grow: 1;
        min-height: 48px;
        justify-self: center;
        .editor-inclusive-text-group {
          align-self: center;
          flex-grow: 1;
          .inclusive-type {
            font-weight: $font-weight-bold;
          }
          .inclusive-help-button {
            cursor: pointer;
            text-decoration: underline;
            color: $blue;
          }
        }
        .editor-inclusive-count {
          display: flex;
          width: 82px;
          min-width: 82px;
          padding: 0 calc($global-padding/2);
          border-right: $global-border;
          border-color: $yellow;
          border-right: none;
          .editor-inclusive-count-text {
            display: flex;
            align-self: center;
          }
        }
      }
    }
    .editor-inclusive-info {
      display: flex;
      min-height: $wsysiwyg-info-size;
      background: $light-yellow;
      border: $global-border;
      border-left: none;
      border-color: $yellow;
      border-bottom-right-radius: $border-radius;

      .editor-inclusive-navigation {
        display: flex;
        min-width: 45px;
        flex-direction: column;
        .img-icon {
          margin: auto;
          width: $icon-size-l;
        }

        .editor-inclusive-up,
        .editor-inclusive-down {
          // padding: 0 1em;
          display: flex;
          flex-grow: 1;
          cursor: pointer;
          &.disabled {
            cursor: default;
            filter: opacity(0.5);
          }
        }
        .editor-inclusive-up {
          border-bottom: $global-border;
          border-color: $yellow;
        }
        .editor-inclusive-up .img-icon {
          transform: rotate(270deg);
        }
        .editor-inclusive-down .img-icon {
          transform: rotate(90deg);
        }
      }
    }

    .inclusive-help-text {
      background: $white;
      border-radius: $border-radius;
      padding: $global-padding;
      margin: $global-margin;
      margin-top: 0;
      .inclusive-help-example-text {
        display: flex;
        padding-top: $global-padding;
        .inclusive-help-icon {
          padding-right: calc($global-padding / 2);
        }
        &.inclusive-help-example-ko {
          .inclusive-help-icon {
            color: $red;
          }
        }
        &.inclusive-help-example-ok {
          .inclusive-help-icon {
            color: $green;
          }
        }
      }
    }
  }
  .top-150 {
    top: 150px !important;
  }
  .editor-inclusive-status-text {
    background: $white;
    position: absolute;
    right: 18px;
    top: 200px;
    height: 35px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: calc($global-padding/2);

    .editor-inclusive-status-text-info {
      display: flex;
      font-weight: $font-weight-bold;
      &.hide {
        display: none;
      }
    }
    .editor-inclusive-status-icon {
      display: flex;
      margin-left: calc($global-margin/4);
      align-self: center;
      font-size: $global-font-size + 2px;
      .bold {
        font-weight: bold;
      }
    }
    &.status-warning {
      .editor-inclusive-status-text-info,
      .editor-inclusive-status-icon {
        color: $yellow;
      }
      .fa-number-insights {
        background-color: $yellow;
        color: $white;
        border-radius: 50%;
        height: 17px;
        width: 17px;
        font-size: $global-font-size;
        font-weight: bold;
        justify-content: center;
        align-items: center;
        &.small-number {
          font-size: $global-font-size - 2px;
        }
      }
      .fa-sync-alt {
        -webkit-animation: rotating 2s linear infinite;
        -moz-animation: rotating 2s linear infinite;
        -ms-animation: rotating 2s linear infinite;
        -o-animation: rotating 2s linear infinite;
        animation: rotating 2s linear infinite;
      }
    }
    &.status-ok {
      .editor-inclusive-status-text-info,
      .editor-inclusive-status-icon {
        color: $success-color;
      }
    }
  }
  @media (max-width: 767px) {
    .editor-inclusive-status-text {
      top: 183px;
    }
  }
}
