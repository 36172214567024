@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// Disable selection
@mixin disable-selection {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.rc-upper-menu {
  .no-photo {
    display: flex;
    div {
      margin: auto;
    }
  }
  .empty-notification-text {
    display: flex;
    align-items: center;
    gap: calc(2 * var(--unit));
    .material-icons {
      font-family: "Material Icons Outlined";
    }
  }
}
#app-header,
#public-header {
  .-rc-text {
    color: var(--headerFontColor);
    font-weight: 600;
    font-size: 22px;
    line-height: 22px;
    display: flex;
    margin-top: auto;
    margin-bottom: 5px;

    @media (max-width: 1295px) {
      display: none !important;
    }
  }

  .-tenant-btn {
    background-color: var(--white);
    display: flex;
    position: relative;
    cursor: pointer;
    max-height: 90%;
    margin-bottom: auto;
    margin-right: 24px;
    padding: 0.5rem 3rem 0.5rem 2rem;
    border-radius: 0px 0px 10px 10px;
    min-width: 250px;
    @include disable-selection;
    @media (max-width: 1200px) {
      max-width: 240px;
      min-width: auto;
    }
    @media (max-width: 992px) {
      padding-right: 2rem;
      padding-left: 1rem;
      margin-right: 12px;
      max-width: 150px;
      min-width: auto;
    }

    .-tenant-btn-container {
      display: flex;
      width: 100%;
      .-tenant-selected {
        font-size: 17px;
        font-weight: 600;
        color: var(--black);
        max-height: 25px;
        margin: auto 0 auto 0.5rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .-language-btn {
    background-color: var(--white);
    display: flex;
    position: relative;
    cursor: pointer;
    margin-bottom: auto;
    margin-right: 24px;
    border-radius: 0px 0px 10px 10px;
    padding: 0.5rem 3rem 0.5rem 1rem;
    min-width: 155px;
    @include disable-selection;
    @media (max-width: 992px) {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .-btn-flag {
      height: 18px;
      margin: auto 1rem auto 0.5rem;
      @media (max-width: 992px) {
        height: 13px;
      }
    }

    .-language-selected {
      font-size: 17px;
      font-weight: 600;
      color: var(--black);
      margin: auto 0.5rem auto 0;
      max-height: 25px;
      white-space: nowrap;
      @media (max-width: 992px) {
        font-size: 14px;
      }
    }
  }
  .-header-selector {
    overflow-y: auto;
    padding-top: 0.5rem;
    background-color: var(--white);
    color: var(--black);
    position: absolute;
    z-index: 700;
    width: 100%;
    max-height: 95vh;
    left: 0;
    top: 31px;
    border-radius: 0px 0px 5px 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    font-size: 17px;
    @media (max-width: 992px) {
      padding-left: 1rem;
    }

    .-tenant-selector {
      cursor: pointer;
      padding-left: 2rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      position: relative;
      display: flex;
      width: 100%;

      .-tenant-text {
        margin: auto 0.5rem auto 0;
      }
    }

    .-select-tenant-selector {
      cursor: pointer;
      padding-left: 2rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      position: relative;
      display: flex;
      margin: auto;
      width: 100%;
      outline: var(--gray200);
    }

    .selector-flag {
      max-width: 26px;
      height: 18px;
      margin-top: auto;
      margin-bottom: auto;
    }

    .-language-selector {
      padding-left: 2rem;
      cursor: pointer;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      position: relative;
      display: flex;

      .-language-text {
        margin: auto 0 auto 1rem;
      }
    }

    .-user-profile-selector {
      padding-right: 0.5rem;
      cursor: pointer;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      position: relative;
      display: flex;

      .-language-text {
        margin: auto 0 auto 1rem;
      }
    }

    .-tenant-selector:hover,
    .-language-selector:hover,
    .-user-profile-selector:hover {
      background-color: var(--gray200);
    }
  }
}

.-lenguage-mv-selector {
  z-index: 1000;
  position: fixed;
  bottom: 20px;
  right: 10px;
  width: 50px;
  height: 50px;
  background-color: green;
  border: 3px white solid;
  border-radius: 25px;
  object-fit: cover;
  cursor: pointer;
}

.-super-pos {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.6);
  z-index: 500;
}

.-romb {
  position: fixed;
  bottom: 77px;
  right: 22px;
  width: 25px;
  height: 25px;
  z-index: 500;
  background: white;
  -webkit-transform: rotate(45deg) skew(15deg, 15deg);
  -moz-transform: rotate(45deg) skew(15deg, 15deg);
  -ms-transform: rotate(45deg) skew(15deg, 15deg);
  -o-transform: rotate(45deg) skew(15deg, 15deg);
  transform: rotate(45deg) skew(15deg, 15deg);
}

.-mv-selector {
  z-index: 999;
  padding: 2rem 0;

  background-color: white;
  position: fixed;
  bottom: 84px;
  right: 10px;
  min-width: 100px;
  max-width: 400px;
  border-radius: 3px;
  font-size: 17px;
  font-weight: 600;
  line-height: 30px;
  cursor: pointer;
  .-language {
    display: flex;
    margin: auto;
    padding: 0 2rem;
    .-lenguage-text {
      margin: auto 1rem auto auto;
    }
    .selector-flag {
      max-width: 30px !important;
      height: 20px !important;
      margin: auto 0;
    }
    &:hover {
      background-color: var(--gray200);
    }
  }
}

.tag-ds .upper-menu_left:after {
  background-color: #ffc133;
  font-size: 18px;
  font-weight: 600;
  margin-left: calc(2 * var(--unit));
  padding: 1px 6px;
}

.tag-ds .upper-menu_left.a:after {
  content: "LOCAL";
}
.tag-ds .upper-menu_left.a2:after {
  content: "LOCAL";
}
.tag-ds .upper-menu_left.b:after {
  content: "LOCAL";
}
.tag-ds .upper-menu_left.d:after {
  content: "DEV";
}
.tag-ds .upper-menu_left.t:after {
  content: "INT";
}
.tag-ds .upper-menu_left.tau:after {
  content: "INT AUS";
}
.tag-ds .upper-menu_left.u:after {
  content: "UAT";
}
.tag-ds .upper-menu_left.uau:after {
  content: "UAT AUS";
}