.tag-ds {
  .drawer-wrapper.modal-preview {
    width: 40vw;
    max-height: 100vh;
    height: 100vh;
    transition: transform 0.5s;
    z-index: 3;
    .drawer-body  {
      overflow: auto;
    }
    .preview-jd {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      padding-right: 0 !important;

      .logo-jd {
        display: flex;
      }
      .info-jd {
        .g-item_16 {
          width: 100%;
        }
      }
      .full-width {
        width: 100%;
      }
    }
  }  
}
