body {
  .proserv-theme {
    --primaryColor: #001d67;
    --headerColor: var(--primaryColor);
    --secondaryColor: var(--white);

    --componentColor: var(--primaryColor);
    --titleHomeProcessColor: var(--white);
    --tabSelectedColor: var(--mainColor);

    //Fonts colors
    --headerFontColor: var(--secondaryColor);
    --breadcrumbFontColor: var(--primaryColor);
    --linkFontColor: var(--white);

    //Button colors
    --primaryButtonColor: var(--primaryColor);
    --secondaryButtonColor: var(--white);
    --hoverButtonColor: var(--primaryColor);
    --disableButtonColor: #c8c7c7;
    --focusButtonColor: #782f40;

    //Active buttons
    --primaryButtonFontColor: var(--white);
    --primaryButtonBorderColor: var(--primaryButtonColor);

    --secondaryButtonFontColor: var(--primaryButtonColor);
    --secondaryButtonBorderColor: var(--primaryButtonColor);

    //Hover Buttons
    --primaryButtonHoverColor: var(--hoverButtonColor);
    --primaryButtonHoverFontColor: var(--white);
    --primaryButtonHoverBorderColor: var(--primaryButtonColor);

    --secondaryButtonHoverColor: var(--white);
    --secondaryButtonHoverFontColor: var(--primaryButtonColor);
    --secondaryButtonHoverBorderColor: var(--primaryButtonColor);

    //Disable Buttons
    --primaryButtonInactiveColor: var(--disableButtonColor);
    --primaryButtonInactiveFontColor: var(--white);
    --primaryButtonInactiveBorderColor: var(--disableButtonColor);

    --secondaryButtonInactiveColor: var(--white);
    --secondaryButtonInactiveFontColor: var(--disableButtonColor);
    --secondaryButtonInactiveBorderColor: var(--disableButtonColor);

    --mainColor: #001d67;

    --dropdownBackgroundColor: var(--white);
    --dropdownElementHoverColor: #e2e2e2;
    --mainLogo: url("../images/proserv-theme/common/logo.png");
    --mainLogoColored: url("../images/proserv-theme/common/logo.png");
    --homeTopImage: url("../images/proserv-theme/home/home-top.png");
    --buttonPrimaryActiveColor: #001d67;
    --buttonPrimaryBorderColor: #001d67;
    --buttonPrimaryActiveFontColor: var(--white);

    --buttonPrimaryHoverColor: var(--white);
    --buttonPrimaryHoverBorderColor: #001d67;
    --buttonPrimaryHoverFontColor: #001d67;

    --buttonPrimaryPressedColor: #782f40;
    --buttonPrimaryPressedFontColor: var(--white);

    --buttonPrimaryInactiveColor: #968c83;
    --buttonPrimaryInactiveFontColor: var(--white);

    --buttonSecondaryActiveColor: var(--white);
    --buttonSecondaryBorderColor: #001d67;
    --buttonSecondaryActiveFontColor: #001d67;

    --buttonSecondaryHoverColor: var(--white);
    --buttonSecondaryHoverBorderColor: #001d67;
    --buttonSecondaryHoverFontColor: #001d67;

    --buttonSecondaryPressedColor: #782f40;
    --buttonSecondaryPressedFontColor: #782f40;

    --buttonSecondaryInactiveColor: #968c83;
    --buttonSecondaryInactiveFontColor: var(--white);
    --buttonBlueActiveColor: #1a3476;
    --buttonBlueActiveFontColor: var(--white);
    --buttonBlueHoverColor: #1a3476;
    --buttonBlueHoverFontColor: var(--white);
    --buttonBluePressedColor: #1a3476;
    --buttonBluePressedFontColor: var(--white);
    --helpButton: url("../images/proserv-theme/candidate/help-button.png");
    --phaseCompleted: url("../images/proserv-theme/candidate/phase-completed.png");
    --phaseFailed: url("../images/proserv-theme/candidate/phase-failed.png");
    --dropdown: url("../images/proserv-theme/common/dropdown.png");
    --candidatePublicBackgroundStart: #001d67;
    --candidatePublicBackgroundEnd: #1a3476;
    --candidateDesktopArrowTop: url("../images/common/no-backg.png");
    --candidateDesktopArrowBottom: url("../images/common/no-backg.png");
    --candidateMobileArrowTop: url("../images/common/no-backg.png");
    --candidateMobileArrowBottom: url("../images/common/no-backg.png");
    --candidateLightArrowTop: url("../images/candidate-web/chevron.png");
    --candidateLightArrowBottom: url("../images/candidate-web/Group@3x-2.png");
    --candidateLightArrowInvert: url("../images/candidate-web/chevron-invert.png");
    --candidateDownloadPdf: url("../images/candidate-web/download-pdf.png");
    --adminServiceFeatureBackground: var(--gray100);
    --adminServiceFeatureBorder: var(--gray200);

    //Font family
    --fontFamily: "Roboto";
    --fontFamilyUrl: "https://fonts.googleapis.com/css?family=Comfortaa:300,400,500,600,700&display=swap";
    --fontFamilyHeaders: "Roboto";
    --fontFamilyBody: "Roboto";
  }

  .proserv-theme .tag-ds .sidebar-logo {
    height: auto;
  }

  .proserv-theme .tag-ds .sidebar-logo img {
    height: auto;
  }
}
