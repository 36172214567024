.tag-ds {
  .header-btn {
    height: 40px;
    font-size: 12px;
    .active {
      background-color: var(--backgrounds-interactive-selected-02);
      color: var(--text-01);
    }
    .actions-container {
      width: 100%;
      background-color: var(--backgrounds-03);
      padding: 15px;
      border-radius: 8px;
    }

    .action-button {
      color: var(--full-palette-secondary-500);
      font-weight: 600;
    }

    .table-item-container {
      color: var(--text-01);
    }
  }

  .section-add {
    border: 1px dashed var(--borders-dividers-01);
    background-color: #f8f8f9;
    border-radius: 8px;
  }

  /*WYSWYG error*/
  .input-wrapper.error {
    .editor-container .editor-toolbar,
    .editor-container .editor-content,
    .editor-container .editor-container-child:last-child.editor-content {
      border-color: $red;
    }
  }

  .preview-jd .medium {
    font-weight: 500;
  }
  .preview-logo {
    max-width: 75%;
    max-height: 100px;
  }

  .link-title {
    display: flex;
    .open-new-icon {
      color: black;
      font-size: 16px;
    }
  }
}
