.adecco-ui {
  .pagination {
    padding: 0;
    text-decoration: none;
    display: flex;
  }
  .pagination .btn {
    margin: 0;
    // background-color: blue !important;
  }
  .pagination-pages,
  .pagination-prev,
  .pagination-first,
  .pagination-next,
  .pagination-last {
    border-radius: 0px;
  }

  .pagination ul {
    display: flex;
    border-radius: 0px !important;
  }

  .pagination .items-per-page {
    margin-bottom: 0;
  }

  .pagination .items-per-page-wrapper {
    align-items: center;
    display: flex;
    margin-left: auto;
    margin-right: 0;
  }
  .pagination .items-per-page-wrapper .flex-item {
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }
  .pagination .items-per-page-wrapper .flex-item:first-child {
    border-radius: $border-radius 0 0 $border-radius;
    border-right: none;
  }
  .pagination .items-per-page-wrapper .flex-item:last-child {
    border-radius: 0 $border-radius $border-radius 0;
  }
  .pagination .items-per-page-wrapper select {
    height: 48px !important;
  }
  .pagination .input-default {
    height: $global-line-height + 15px;
    padding-right: $global-padding * 3;
    border: $light-border;
  }

  @media (max-width: $tablet) {
    .pagination {
      display: block;
    }
    .pagination ul {
      text-align: center;
      width: 100%;
    }
  }
}
