.results_deprecated {
  width: 100%;

  .paginator-page_active, .paginator-page_active:hover {
    background: var(--componentColor) !important;
  }

  .results-summary-container {
    display: flex;
    margin-bottom: 10px;
    .chips-group {
      display: flex;
      flex-wrap: wrap;
      .chip-item {
        button {
          background-color: var(--gray100) !important;
          border-radius: calc(0.75 * var(--unit)) !important;
          padding: 5px 10px !important;
          margin-right: calc(2.5 * var(--unit));
          color: #000;
          height: 28px;
          font-size: 13px;
        }

        span {
          height: 28px;
          width: 20px;
          background-color: var(--buttonPrimaryActiveColor) !important;
          color: var(--white);
          margin: -10px -10px -10px 5px !important;
          padding: 5px !important;
          border-radius: 0 5px 5px 0;
          outline: none !important;
          font-size: 13px;
        }
      }
    }
  }
  .table-content {
    position: relative;
    align-items: center;
    display: flex;
    min-width: max-content;
    .sortable-button {
      background: transparent;
      margin: 0 0 0 4px;
      padding: 4px;
      color: #adadb5;
      .material-icons {
        font-size: calc(var(--unit) * 2) !important;
        display: flex;
        align-items: center;
      }
    }

    button:active,
    button:hover,
    button:focus {
      background: transparent !important;
    }
    p {
      margin: 0px;
      font-size: 15px !important;
      color: var(--gray500);
      font-weight: 600;
      font-family: var(--fontFamily);
    }
  }
  table thead td .resize {
    position: absolute;
    top: 0;
    right: -10px;
    width: 5px;
    cursor: col-resize;
    height: 100%;
    border-right-style: solid;
    border-right-color: $light-grey;
    border-right-width: 1.5px;
  }
  table thead td:last-child .resize {
    display: none;
  }
  table thead td {
    vertical-align: middle;
    border-right: 1.5px solid #d3d3d3;
    font-weight: 700;
  }

  .data-table-paginator {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    grid-gap: 8px;
    align-items: center;
    margin-bottom: 25px;

    button:active,
    button:hover,
    button:focus {
      background-color: transparent !important;
    }
  }
  .paginator-holder {
    display: flex;
    flex-wrap: wrap;
    margin-top: -10px;
    margin-bottom: 30px;
    .items-per-page-holder {
      display: flex;
      flex-basis: 50%;
      align-items: center;
      margin-top: 0;
      .items-per-page {
        width: 100px;
      }
      .input-wrapper {
        margin-bottom: 10px;
      }
      span {
        margin-left: 10px;
        margin-bottom: 10px;
      }
    }
    .paginator {
      flex-basis: 50%;
    }
  }
  .dropdown-filter {
    .dropdown {
      position: relative;
      left: 0;
      right: 0;
      .dropdown-menu {
        ul {
          max-width: 40vw;
        }
      }
    }
  }
  .data-table-filter-header-btn {
    color: var(--icons-02);
    background-color: transparent !important;
    margin: 0 !important;
    padding: 4px !important;
    min-width: auto !important;

    span {
      font-size: calc(var(--unit) * 2) !important;
    }
  }
  .data-table-filter-header-btn:hover,
  .data-table-filter-header-btn:focus {
    color: var(--icons-02);
  }
  .material-icons {
    font-family: "Material Icons Outlined";
  }

  .solid-grey-box {
    background-color: #f1f1f1 !important;
    padding: 12px;
  }

  .chip_active {
    background-color: var(--backgrounds-interactive-selected-02) !important;
    border: 1px solid var(--backgrounds-interactive-selected-02) !important;
  }

  .select-bottom-container {
    button:hover,
    button:focus {
      background-color: var(--backgrounds-interactive-selected-02) !important;
      border: 1px solid var(--backgrounds-interactive-selected-02) !important;
      color: var(--text-01);
      border: 1px solid var(--borders-dividers-interactive-hover) !important;
    }
  }

  .notification_relative {
    max-width: 100% !important;
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    transform: none !important;
    z-index: 0;
  }
}

.home-candidates-container {
  .table-content {
    display: flex;
    justify-content: center;
  }
}
