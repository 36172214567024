.adecco-ui {
  .step-container {
    display: flex;
    flex-direction: row;
  }
  .step {
    margin: 0 $global-margin;
    padding: $global-padding $global-padding;
  }
  .step.disabled {
    color: $dark-grey;
  }
  .step.active {
    border-bottom: 4px solid black;
  }
  .step.passed:before {
    content: "\f058";
    font-family: "Font Awesome 5 Free", sans-serif;
    margin-right: calc($global-margin/2);
    color: $green;
  }

  .-map-icon {
    display: flex;
    width: 13px;
    margin-right: 10px;
    margin-top: -10px;
    cursor: pointer;
  }

  .-map-select {
    font-size: 15px;
    cursor: pointer;
    text-decoration: underline;
  }

  .add-section {
    position: absolute;
    right: 0;
    transform: translateX(-110px);
    @media (max-width: $mobile) {
      transform: translateY(-20px);
    }
  }
  .color-palette {
    width: 70px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .trash-icon {
    background: transparent !important;
    position: absolute;
    color: $dark-grey;
    font-size: 15px;
    right: 0;
    z-index: 1;
    transform: translate(-40px, -5px);
    @media (max-width: $mobile) {
      transform: translateX(-5px);
    }
    &:hover,
    &:focus,
    &:active {
      color: $dark-grey;
    }
  }
  .redo-icon {
    // position: absolute;
    // font-size: 17px;
    // color: $black;
    // transform: translate(70px, -45px);
    &:hover,
    &:focus,
    &:active {
      color: $black;
    }
  }
  .heart-icon {
    position: absolute;
    font-size: 17px;
    color: $black;
    right: 0;
    &:hover,
    &:focus,
    &:active {
      color: $black;
    }
  }
  .share-icon {
    position: absolute;
    font-size: 17px;
    color: $black;
    right: 0;
    top: 50px;
    &:hover,
    &:focus,
    &:active {
      color: $black;
    }
  }
  .wallet-icon:before {
    font-family: "Font Awesome 5 Free", sans-serif;
    content: "\f555";
    color: $grey;
    font-weight: 900;
    margin-right: $global-margin;
  }

  .clock-icon:before {
    font-family: "Font Awesome 5 Free", sans-serif;
    content: "\f017";
    color: $grey;
    font-weight: 900;
    margin-right: $global-margin;
  }
  .company-logo {
    position: relative;
    width: 54px;
    height: 54px;
    background-color: $grey;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .no-logo-wrapper {
    background-color: $default-primary-color !important;
    @include themes($themes) {
      background-color: themed("primary-color") !important;
    }
  }
  .company-logo img.no-logo {
    display: block;
    position: absolute;
    top: 50%;
    max-width: calc(100% - 5px);
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .preview-intro {
    @extend .h4;
    margin-top: $global-margin * 2;
    margin-bottom: $global-margin * 2;
    border-top: 1px solid $light-grey;
    border-bottom: 1px solid $light-grey;
    padding-top: $global-padding * 2;
  }
  .preview-intro * {
    @extend .h4;
  }

  .job-listing {
    .select-filter-container .inputs-group > .input-group-item:first-child .tag-button {
      margin-left: calc($global-margin / 2);
      margin-right: calc($global-margin / 2);
      margin-top: calc($global-margin / 2);
      margin-bottom: calc($global-margin / 2);
      border-radius: $border-radius !important;
      .btn-grey {
        border-radius: 0 $border-radius $border-radius 0 !important;
        padding-bottom: calc($global-padding / 3) !important;
      }
    }
    .input-wrapper.valid .select-filter {
      .inputs-group:after {
        display: none;
      }
    }
  }
  .link-contact-use {
    font-weight: $font-weight-bold;
    color: $link-color;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .button-template-contact {
    text-align: right;
    &:hover {
      cursor: pointer;
    }
    .button-contact-text {
      font-weight: $font-weight-bold;
      text-decoration: underline;
    }
    .icon-template-contact {
      height: 1em;
      padding-right: 0.25em;
    }
  }

  .template-contact-zone {
    position: relative;
    .form-template-contact {
      background-color: $input-background;
      border-radius: $border-radius;
    }
  }

  .wrapper-contact-info-form {
    flex-wrap: wrap;
    div.input-contat-info-form {
      flex: 100%;
    }
    section.input-contat-info-form {
      flex: 45%;
    }
    .input-contat-info-form {
      margin-bottom: 1.5em !important;
      .input-wrapper {
        margin-bottom: 0;
      }
    }
    section:nth-of-type(2n) {
      margin-left: 1em;
    }

    .input-wrapper.valid {
      input {
        border-color: $dark-grey;
      }
      .input-container {
        &::after {
          color: $dark-grey;
          display: none;
        }
      }
      &.loading {
        .input-container {
          &::after {
            display: block;
          }
        }
      }
    }
  }

  .container-post-cart {
    background-color: $light-grey;
    padding: $global-padding * 0.5;
    border-radius: $border-radius;

    .post-cart-sections {
      padding: $global-padding;
      border-radius: $border-radius;
      background-color: $white;
      box-shadow: 1px 1px 3px 3px rgba(128, 128, 128, 13%);
    }

    .post-cart-header {
      display: flex;
      justify-content: space-around;

      .post-cart-title {
        align-self: center;
        margin-bottom: 0px;
      }
      .post-cart-subtitle {
        text-align: center;
      }
      .post-cart-loading {
        padding-top: $global-padding;
        flex-basis: 100%;
        .loading-bar-layout {
          position: unset !important;
          #loading-context {
            text-align: center;
          }
        }
      }
    }

    .post-cart-item {
      cursor: move;
      background: $light-grey;
      color: $black;
      border-radius: $border-radius;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .post-cart-item-text {
        padding: 4px 0px 0px 8px;
        font-size: 14px;
        font-weight: $font-weight-bold;
      }
      .post-cart-item-cost {
        padding: 0px 0px 4px 8px;
        font-size: 10px;
        font-weight: $font-weight-bold;
        color: $dark-grey;
      }
      .post-cart-item-remove {
        font-weight: $font-weight-bold;
        padding: $global-padding;
        margin-right: 0;
        border-radius: 0 5px 5px 0;
      }
    }

    .post-cart-item-resume {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
    }
  }
  &.admin-sections {
    .loading {
      display: flex;
      justify-content: center;
    }
    .container-dragdrop {
      background-color: $light-grey;
      padding: $global-padding * 2;
      border-radius: $border-radius;
      .container-dragdrop-form {
        display: flex;
        margin-bottom: $global-margin;
        .select-form {
          flex-grow: 1;
          margin-bottom: 0;
        }
        .button-form {
          margin-right: 0;
          margin-left: $global-margin;
        }
      }
      .zone-dragdrop-sections {
        padding: $global-padding;
        border-radius: $border-radius;
        background-color: $white;
        box-shadow: 1px 1px 3px 3px rgba(128, 128, 128, 13%);
        &.dropZone {
          background-color: rgba(0, 0, 0, 0.1);
          border: 2px black dashed;
          transition: all 0.2s ease-in-out;
          .section-draggable:hover {
            background-color: $grey;
          }
          .section-droppable {
            transform: scale(1.01);
            transition: transform 0.5s ease-in-out;
          }
        }
        .info-no-sections {
          text-align: center;
          font-style: italic;
          font-size: $icon-size-l;
          font-weight: $font-weight-bold;
          margin: $global-margin;
        }
        .section-draggable {
          cursor: move;
          background: $light-grey;
          color: var(--text-01);
          border-radius: $border-radius;
          display: flex;
          justify-content: space-between;
          margin-bottom: $global-margin;
          .text-zone {
            padding: $global-padding;
          }
          .remove-zone {
            font-weight: bold;
            padding: $global-padding;
            margin-right: 0;
            border-radius: 0 $border-radius $border-radius 0;
          }
        }
      }
    }
    .zone-buttons {
      margin-top: $global-margin;
      display: flex;
      justify-content: flex-end;
      button {
        min-width: 10em;
      }
    }
  }
  .kldbFieldGroup {
    .select-filter-menu-option {
      &.no-icon,
      &.no-icon:hover {
        &:before {
          display: none;
        }
      }
    }
  }
  &.modal-order-section.modal {
    width: 490px !important;
    .modal-header {
      padding: 15px;
    }
  }

  .jp-accordion table {
    margin: 0 !important;
  }

  .description-warning-modal,
  .description-add-section-modal {
    z-index: 701 !important;
  }

  .description-section-div {
    position: relative;
  }

  .info-tooltip-absolute {
    position: absolute;
    right: 0;
    top: 10px;
    z-index: 1;
  }
  .-information-option-template {
    background-color: $light-grey;
    border-radius: $border-radius;
    font-size: $global-font-size;
  }
  .-additional-locations {
    margin-bottom: 4 * $global-margin;
    .-additional-location-display {
      display: flex;
      justify-content: space-between;
    }
    .-add-location {
      display: flex;
      justify-content: flex-end;
    }
  }
  &.-job-description-add-location {
    .-zone-buttons {
      display: flex;
      justify-content: flex-end;
    }
  }

  .info-status-jd {
    font-size: $global-line-height;
  }

  .info-detail:hover .jobdescription-info-detail,
  .jobdescription-info-detail:hover {
    display: block;
  }

  .jobdescription-info-detail {
    display: none;
    position: absolute;
    z-index: 100;
  }

  .jobdescription-info-detail-inner {
    top: 50;
    z-index: 100;
    background-color: var(--white);
    border-radius: var(--globalBorderRadius);
    box-shadow: 0 0 4px 4px var(--gray200);
    padding: 1rem;
    margin-left: 2rem;
    min-width: 400px;
    transform: translateY(-50%);
    p {
      margin-bottom: $global-margin;
    }
  }
}
