#app-sidebar {
  .sidebar {
    width: calc(24 * var(--unit));
    .main_logo {
      cursor: pointer;
    }
  }
  &.rc-sidebar {
    &.mobile_sidebar {
      & + #app-content {
        display: none;
      }
      .sidebar {
        position: absolute;
        width: 100vw !important;
        min-width: 100vw !important;
        max-width: 100vw !important;
      }
      .sidebar-logo {
        height: 70px;
      }
    }
  }
  &.rc-sidebar_collapsed {
    & + #app-content {
      padding-left: calc(10 * var(--unit)) !important;
    }
  }
}

.country-flag {
  margin-left: 4px;
  height: 12px;
}
