.-admin-panel {
  height: 100%;
  width: 100%;
  background-color: rgb(240, 240, 240);
  display: inline-table;
  .-card-container {
    background-color: white;
    border-radius: 10px;
    width: 90%;
    margin-left: 5%;
    margin-top: 30px;
    padding: 30px;
    text-align: right;
  }
}
