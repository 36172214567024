.date-picker-container {
  position: relative;
  width: 100%;

  i {
    position: absolute;
    font-size: 17px;
    top: 15px;
    right: 15px;
  }

  .react-datepicker-popper {
    min-width: 340px !important;
  }

  .calendar-img {
    position: absolute;
    width: 15px;
    height: 15px;
    top: 15px;
    right: 16px;
    color: var(--componentColor);
  }

  .date-picker {
    width: 100%;
    border: 1.5px var(--gray200) solid;
    border-radius: var(--globalBorderRadius);
    padding: var(--globalPaddingButton);
    outline: none;
    box-shadow: 0px 2px var(--gray200);
    -moz-appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
  }

  .react-datepicker__navigation--next {
    margin-right: 15px !important;
  }

  .date-picker-calendar {
    background: white;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker {
    border: 1px var(--gray200) solid;
    border-radius: var(--globalBorderRadius);
    padding: 5px;
    font-family: var(--fontFamily);
    cursor: default;
    .react-datepicker__header {
      background: white;
      border: 0 !important;
    }

    .react-datepicker__day--selected {
      background: var(--componentColor);
      color: white;
    }
    .react-datepicker__day {
      border: 1.5px solid var(--componentColor);
      border-radius: 5px;
      font-size: 15px;
      font-weight: 600;
    }

    .react-datepicker__day--disabled {
      border: 1.5px solid rgba($color: #c8c7c7, $alpha: 0.5);
    }
    .react-datepicker__day:hover {
      outline: var(--componentColor);
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected {
      border-radius: 0.3rem;
      background-color: var(--componentColor);
      color: #fff;
    }
  }
}

.react-datepicker {
  border: 1.5px solid rgba($color: #c8c7c7, $alpha: 0.5);
  border-radius: 5px;
  padding: 5px;
  font-family: var(--fontFamily);

  cursor: default;
  .react-datepicker__header {
    background: white;
    border: 0 !important;
  }
  .react-datepicker__day--selected {
    background: var(--buttonPrimaryActiveColor);
    color: white;
  }
  .react-datepicker__day {
    border: 1.5px solid var(--mainColor);
    border-radius: 5px;
    font-size: 15px;
    font-weight: 600;
  }

  .react-datepicker__day--disabled {
    border: 1.5px solid rgba($color: #c8c7c7, $alpha: 0.5);
  }
  .react-datepicker__day:hover {
    outline: var(--mainColor);
  }

  .react-datepicker__navigation--next {
    margin-right: 15px !important;
  }
}

.-timezone-info {
  margin-left: 0.5rem;
  margin-top: 0.8rem;

  .-timezone {
    color: var(--gray300);
    cursor: pointer;
  }

  .-timezone-data {
    border-radius: 5px;
    padding: 10px;
    position: absolute;
    background-color: var(--white);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    z-index: 999;
  }
}
