// html {
//   scroll-behavior: smooth;
// }
// body {
//   margin: 0;
//   font-family: $global-font-family;
// }
.adecco-ui {
  ol,
  ul {
    margin: 0 0 $global-margin * 2 $global-margin * 2;
    padding: 0;
  }
  li {
    margin-bottom: calc($global-margin/2);
  }
  img {
    max-width: 100%;
  }
  pre {
    margin-bottom: $global-margin * 2;
  }
  hr {
    border-bottom: 1px solid $grey;
  }
  .ui-section {
    margin-bottom: $global-margin * 4;
  }

  strong {
    font-weight: bold;
  }
}