.tag-ds {
  .error {
    border-color: var(--full-palette-semantic-error-600);
  }
  .btn {
    background-color: var(--white);
    border: none;
    &:hover {
      background-color: var(--backgrounds-03);
      border: none;
    }
  }
  textarea[readonly],
  .editor-content[contenteditable="false"] {
    border: 1px solid !important;
    color: var(--text-03);
  }
}
// Untill ventas fix it
.tag-ds {
  .radio-container.error input[type="radio"] {
    border-color: var(--borders-dividers-interactive-error) !important;
  }
}
