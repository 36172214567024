.contextConfigurationModal {
  min-width: 567px;
}

.country-flag-background {
  background-size: 26px;
  background-position-x: 150px;
  background-position-y: 12px;
  background-repeat: no-repeat;
}

.country-flag-language-selector {
  display: inline;
  height: 15px;
  z-index: 1;
  position: relative;
  top: 80px;
  left: 120px;
}

.withFlag {
  position: relative;
  top: -35px;
}
