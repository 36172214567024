body {
  .onsite-theme {
    --mainColor: #e1001a;
    --headerColor: var(--mainColor);
    --secondaryColor: #ffffff;
    --dropdownBackgroundColor: var(--white);
    --dropdownElementHoverColor: #e2e2e2;
    --mainLogo: url("../images/onsite-theme/common/logo.png");
    --mainLogoColored: url("../images/onsite-theme/common/logo-color.jpg");
    --homeTopImage: url("../images/onsite-theme/home/home-top.png");
    --buttonPrimaryActiveColor: #da291c;
    --buttonPrimaryBorderColor: #da291c;
    --buttonPrimaryActiveFontColor: var(--white);
    --buttonPrimaryHoverColor: #bf0d3e;
    --buttonPrimaryHoverBorderColor: #bf0d3e;
    --buttonPrimaryHoverFontColor: var(--white);
    --buttonPrimaryPressedColor: #782f40;
    --buttonPrimaryPressedFontColor: var(--white);
    --buttonPrimaryInactiveColor: #c8c7c7;
    --buttonPrimaryInactiveFontColor: var(--white);
    --buttonSecondaryActiveColor: var(--white);
    --buttonSecondaryBorderColor: #da291c;
    --buttonSecondaryActiveFontColor: #da291c;
    --buttonSecondaryHoverColor: var(--white);
    --buttonSecondaryHoverBorderColor: #bf0d3e;
    --buttonSecondaryHoverFontColor: #bf0d3e;
    --buttonSecondaryPressedColor: #782f40;
    --buttonSecondaryPressedFontColor: #782f40;
    --buttonSecondaryInactiveColor: #c8c7c7;
    --buttonSecondaryInactiveFontColor: #c8c7c7;
    --buttonBlueActiveColor: #00b5f0;
    --buttonBlueActiveFontColor: var(--white);
    --buttonBlueHoverColor: #0096ef;
    --buttonBlueHoverFontColor: var(--white);
    --buttonBluePressedColor: #196fc9;
    --buttonBluePressedFontColor: var(--white);
    --helpButton: url("../images/onsite-theme/candidate/help-button.png");
    --phaseCompleted: url("../images/onsite-theme/candidate/phase-completed.png");
    --phaseFailed: url("../images/onsite-theme/candidate/phase-failed.png");
    --dropdown: url("../images/onsite-theme/common/dropdown.png");
    --candidatePublicBackgroundStart: #da291c;
    --candidatePublicBackgroundEnd: #bf0d3e;
    --candidateDesktopArrowTop: url("../images/onsite-theme/candidate-web/top-desktop.png");
    --candidateDesktopArrowBottom: url("../images/onsite-theme/candidate-web/bottom-desktop.png");
    --candidateMobileArrowTop: url("../images/onsite-theme/candidate-web/arrow-public-top.png");
    --candidateMobileArrowBottom: url("../images/onsite-theme/candidate-web/arrow-public-bottom.png");
    --candidateLightArrowTop: url("../images/candidate-web/chevron.png");
    --candidateLightArrowBottom: url("../images/candidate-web/Group@3x-2.png");
    --candidateLightArrowInvert: url("../images/candidate-web/chevron-invert.png");
    --candidateDownloadPdf: url("../images/candidate-web/download-pdf.png");
    --adminServiceFeatureBackground: var(--gray100);
    --adminServiceFeatureBorder: var(--gray200);

    --fontColor: var(--white);
    --linkFontColor: #00a9e0;
    --black: #000000;
    --white: #ffffff;
    --gray100: #f0f0f0;
    --gray200: #d4d4d4;
    --gray300: #888;
    --gray400: #555;
    --errorColor: #e1001a;
     --titleHomeProcessColor: var(--white);
    --tabSelectedColor: var(--mainColor);
    
    //Font family
    --fontFamily: "HouschkaHead";
    --fontFamilyUrl: "https://fonts.googleapis.com/css?family=Comfortaa:300,400,500,600,700&display=swap";
    --fontFamilyHeaders: "HouschkaHead";
    --fontFamilyBody: "HouschkaHead";
  }
}
