.adecco-ui {
  .smooth-transition {
    -webkit-transition: all $transition ease-in-out;
    -moz-transition: all $transition ease-in-out;
    -o-transition: all $transition ease-in-out;
    -ms-transition: all $transition ease-in-out;
  }

  .smooth-transition-color {
    transition: color $transition ease-in-out, filter $transition ease-in-out, background $transition ease-in-out, border $transition ease-in-out;
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
