// Import global themes
@import "bootstrap";
@import "../themes/theme.scss";

.cursor-pointer {
  cursor: pointer;
}

.mx-auto,
.my-auto,
.m-auto {
  align-self: center;
}

input[type="number"] {
  max-width: 100px;
}

.overflow-y-auto {
  overflow-y: auto;
}

.loading-bar-layout {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  height: 100vh;
  width: 100vw;
  background-color: rgba($color: white, $alpha: 0.5);
  display: flex;
  .loading-main {
    margin: auto;
    .spinner,
    &.spinner {
      width: calc(4 * var(--unit));
      height: calc(4 * var(--unit));
    }
  }
  .loader-wrapper.tag-ds {
    margin: auto;
  }
}

.loading-bar {
  margin: auto;
  align-self: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  border: none;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  color: var(--componentColor);
  background-color: transparent;
  font-size: 25px;
  overflow: hidden;
}

.loading-bar::-webkit-progress-bar {
  background-color: transparent;
}

/* Indeterminate */
.loading-bar:indeterminate {
  -webkit-mask-image: linear-gradient(transparent 50%, black 50%), linear-gradient(to right, transparent 50%, black 50%);
  mask-image: linear-gradient(transparent 50%, black 50%), linear-gradient(to right, transparent 50%, black 50%);
  animation: loading-bar 6s infinite cubic-bezier(0.3, 0.6, 1, 1);
}

:-ms-lang(x),
.loading-bar:indeterminate {
  animation: none;
}

.loading-bar:indeterminate::before,
.loading-bar:indeterminate::-webkit-progress-value {
  content: "";
  display: block;
  box-sizing: border-box;
  margin-bottom: 0.25em;
  border: solid 0.25em transparent;
  border-top-color: currentColor;
  border-radius: 50%;
  width: 100% !important;
  height: 100%;
  background-color: transparent;
  animation: loading-bar-pseudo 0.75s infinite linear alternate;
}

.loading-bar:indeterminate::-moz-progress-bar {
  box-sizing: border-box;
  border: solid 0.25em transparent;
  border-top-color: currentColor;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-color: transparent;
  animation: loading-bar-pseudo 0.75s infinite linear alternate;
}

.loading-bar:indeterminate::-ms-fill {
  animation-name: -ms-ring;
}

.tooltip-inner {
  background-color: #393939 !important;
  color: white !important;
  border-radius: 6px !important;
  padding: 15px !important;
}

.-help-tooltip {
  margin-left: 10px;
  width: 18px;
  height: 18px;
  background-image: var(--helpButton);
  background-repeat: no-repeat;
  background-size: 18px 18px;
}

@keyframes loading-bar {
  0% {
    transform: rotate(0deg);
  }
  12.5% {
    transform: rotate(180deg);
    animation-timing-function: linear;
  }
  25% {
    transform: rotate(630deg);
  }
  37.5% {
    transform: rotate(810deg);
    animation-timing-function: linear;
  }
  50% {
    transform: rotate(1260deg);
  }
  62.5% {
    transform: rotate(1440deg);
    animation-timing-function: linear;
  }
  75% {
    transform: rotate(1890deg);
  }
  87.5% {
    transform: rotate(2070deg);
    animation-timing-function: linear;
  }
  100% {
    transform: rotate(2520deg);
  }
}

@keyframes loading-bar-pseudo {
  0% {
    transform: rotate(-30deg);
  }
  29.4% {
    border-left-color: transparent;
  }
  29.41% {
    border-left-color: currentColor;
  }
  64.7% {
    border-bottom-color: transparent;
  }
  64.71% {
    border-bottom-color: currentColor;
  }
  100% {
    border-left-color: currentColor;
    border-bottom-color: currentColor;
    transform: rotate(225deg);
  }
}

.-footer-bottom {
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  background: transparent;
  font-size: 14px;
  font-weight: 700;
  color: var(--gray300);
}
.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  color: var(--gray300);
  text-align: center;
  height: 20px;
}
.-none {
  display: none;
}
.-w100 {
  width: 100%;
}

.-help-close-img {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 25px;
  width: 25px;
  cursor: pointer;
}

@media (max-width: 672px) {
  .-help-close-img {
    height: 20px;
    width: 20px;
  }
}
