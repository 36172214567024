.tag-ds {
  .input-wrapper,
  .input-wrapper_disabled {
    margin-bottom: calc(3 * var(--unit));
    .dropdown {
      width: 100%;
    }
  }
  .input-wrapper.error {
    .chip {
      border: 1px solid var(--borders-dividers-interactive-error);
    }
  }
  .input-wrapper_disabled {
    .dropdown {
      width: 100%;
    }
  }
  fieldset {
    margin-bottom: calc(2 * var(--unit));
    & .inline-input-container:first-child {
      margin-top: 0;
    }
    .inline-input-container,
    .tag-ds.inline-input-container {
      margin: calc(1 * var(--unit)) 0;
    }
  }
  .input-error {
    color: $red;
    font-size: $small-font-size;
  }
}

.adecco-ui {
  label {
    display: flex;
    gap: 8px;
  }
  .fake-input + label {
    display: inline-block;
    margin-left: 8px;
  }
  label .required {
    order: 1;
    color: $red;
  }
  label .label-text {
    order: 0;
  }
  .input-default {
    @extend .global-font;
    width: 100%;
    max-width: inherit;
    height: $global-line-height + 30px;
    padding: 0 15px;
    border: $global-border;
    border-radius: $border-radius; /*margin-bottom: $global-margin;*/ /* Remove First */
    background-repeat: no-repeat;
    background-color: $input-background;
    background-size: 25px;
    background-position: right 10px top 10px;
  }
  .input-default:disabled,
  .input-default.disabled,
  .editor-content[contenteditable="false"] {
    border-color: $input-background !important;
    opacity: 1;
    color: $dark-grey;
  }

  .input-wrapper.auto-width {
    display: inline-block;
  }
  .input-wrapper.auto-width .input-default {
    width: auto !important;
  }

  input[type="text"],
  input[type="number"],
  input[type="password"],
  input[type="tel"],
  input[type="url"],
  input[type="email"],
  input[type="date"],
  input[type="time"],
  .editor-container {
    @extend .input-default;
  }

  input[type="password"]::-ms-reveal,
  input[type="password"]::-ms-clear {
    display: none;
  }
  select {
    @extend .input-default;
  }
  select[multiple] {
    height: auto;
    display: inline-block;
    width: 100%;
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    line-height: 1.5;
  }
  input[type="date"] {
    height: 36px;
    border: $global-border;
    padding-left: 5px;
  }
  textarea {
    @extend .input-default;
    height: 92px;
    padding: 15px;
    resize: none;
    display: block;
    &:read-only {
      // copy from disabled
      border-color: $input-background;
      opacity: 1;
      color: $dark-grey;
      // custom
      outline: 0px;
      cursor: default;
    }
  }
  /*button*/

  .btn,
  input[type="submit"],
  input[type="button"],
  button {
    @extend .global-font;
    display: inline-block;
    color: White;
    border: none;
    margin-right: $global-margin;
    background: $default-primary-color;
    @include themes($themes) {
      background: themed("primary-color");
    }
    padding: 14px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 6px;
    vertical-align: inherit;
    @extend .smooth-transition-color;
    outline: none !important;
  }
  .btn:not(span) {
    cursor: pointer;
  }
  input[type="submit"],
  input[type="button"],
  button {
    @extend .btn;
  }

  /*small button*/
  .btn-small {
    font-size: $small-font-size;
    padding: calc($global-padding/2) $global-padding;
  }
  .small .btn,
  small .btn,
  .btn.small {
    @extend .btn-small;
  }
  /*small input*/
  .input-default.small,
  .small .input-default,
  small .input-default {
    height: $global-line-height + 9px;
    font-size: $small-font-size;
  }
  /*small textarea*/
  small textarea,
  .small textarea,
  textarea.small {
    height: 40px;
  }

  /*button disabled*/
  %btn-disabled,
  .btn.disabled {
    pointer-events: none;
    text-decoration: none;
    opacity: 0.45;
  }
  .btn:disabled,
  input[type="submit"]:disabled,
  input[type="button"]:disabled {
    @extend %btn-disabled;
  }

  /*button active*/
  // Placeholder selector %btn-active must be added because compound selectors
  // Can't be added with the new sass version
  %btn-active,
  .btn.active {
    text-decoration: none;
    @include themes($themes) {
      background: themed("active-color");
    }
    color: #fff;
  }
  .btn,
  input[type="submit"],
  input[type="button"] {
    &:not(span) {
      &:hover,
      &:focus,
      &:active {
        @extend %btn-active;
      }
    }
  }
  .form-wizard-btn {
    justify-content: right;
    &.top-buttons-group {
      position: absolute;
      right: 15px;
    }
    &.bottom-buttons-group {
      margin-right: 0;
    }
    &.top-buttons-group,
    &.bottom-buttons-group {
      button:last-of-type {
        margin-right: 0px;
      }
    }
  }
  .espace-button {
    padding-top: 3rem;
  }

  .btn-transparent:hover,
  .btn-transparent:active,
  .btn-transparent:focus {
    outline: 0;
    border: none;
    background: none;
    opacity: 0.6;
    color: $font-color !important;
  }
  .fake-input {
    font-family: "Font Awesome 5 Free", sans-serif;
    cursor: pointer;
    display: inline-block;
    position: relative;
    text-decoration: none;
    width: 18px;
    color: $dark-grey;
  }
  a.fake-input {
    text-decoration: none;
  }
  .fake-input.checkbox:before {
    content: "\f0c8";
    pointer-events: none;
    font-weight: 300;
  }
  .fake-input.checkbox.active:before {
    content: "\f14a";
    font-weight: 900;
  }
  .fake-input.checkbox {
    content: "\f14a";
  }
  .fake-input.radio:before {
    content: "\f111";
    pointer-events: none;
  }
  .fake-input.radio.active:before {
    content: "\f192";
    font-weight: 900;
  }
  .fake-input.radio {
    content: "\f192";
  }
  .fake-input input,
  .fake-select select {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .fake-input:hover,
  .fake-input:focus {
    text-decoration: none;
  }

  .fake-select {
    display: inline-block;
    // *display: inline;
    // *zoom: 1;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    @extend .input-default;
  }
  .fake-select-arrow:before {
    content: "\f107";
  }
  .fake-select select {
    display: none;
  }
  .fake-select-arrow {
    margin-right: 10px;
    margin-top: -7px;
    position: absolute;
    right: 0;
    top: 50%;
    width: 10px;
    display: inline-block;
    //font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @include themes($themes) {
      color: themed("active-color");
    }
    // *zoom: 1;
    // *display: inline;
  }
  .fake-select-active-opt {
    display: block;
    line-height: 34px;
    outline: medium none;
    padding: 0 30px 0 10px;
    text-decoration: none;
  }
  .fake-select-options-container {
    display: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    z-index: 1;
  }
  .fake-select-options-container ul {
    border: 1px solid #e6e6e6;
    list-style: none outside none;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    position: relative;
  }
  .fake-select-options-container li {
    background: #fff;
    display: block;
    margin: 0;
    padding: 0;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }
  .fake-select-options-container li a {
    display: block;
    line-height: 20px;
    outline: medium none;
    padding: 5px 30px 5px 10px;
    text-decoration: none;
    color: #000;
  }
  .fake-select-options-container li a:hover {
    @include themes($themes) {
      background: themed("active-color");
    }
    color: #fff;
  }

  fieldset {
    display: inline-block;
  }

  /*inputs wrap*/
  .inputs-group {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
  }
  .inputs-group .input-group-item {
    display: block;
    margin: 0;
    position: relative;
    margin-bottom: 0px;
    flex: inherit;
    width: auto;
  }
  .inputs-group .input-group-item.fill {
    flex: 1;
  }
  // .inputs-group > .input-group-item {
  // border-radius: 0px;
  // }
  .inputs-group > .input-group-item:first-child input,
  .inputs-group > .input-group-item:first-child select,
  .inputs-group > .input-group-item:first-child .btn,
  .inputs-group > .input-group-item:first-child.btn {
    border-radius: $border-radius 0px 0px $border-radius !important;
    border-right: none;
  }
  .inputs-group > .input-group-item:last-child input,
  .inputs-group > .input-group-item:last-child select,
  .inputs-group > .input-group-item:last-child .btn,
  .inputs-group > .input-group-item:last-child.btn {
    border-radius: 0px $border-radius $border-radius 0px !important;
  }

  /********** END FORMS **********/

  .input-wrapper {
    position: relative;
    margin-bottom: 4 * $global-margin;
  }

  .input-wrapper.input-wrapper-radio,
  .input-wrapper.input-wrapper-checkbox {
    margin-bottom: $global-margin;
  }
  .input-icon {
    content: " ";
    background-size: 100% 100%;
    display: block;
    height: 10px;
    width: 10.5px;
    position: absolute;
    top: 27px;
    right: 20px;
    margin-right: 10px;
  }

  .label-base {
    position: absolute;
    left: 10px;
    top: 20px;
    transform: translate(0);
    margin: 0 15px;
  }
  .label-moved {
    opacity: 1;
    top: 13px;
    transform: translateY(-100%);
    line-height: 18px;
    font-size: 10px;
    color: $font-color;
  }

  select ~ .input-label {
    opacity: 0;
  }

  .input-default.green-bg:not(.valid):not(.error) ~ .input-label {
    color: #fff;
  }
  .move-label .input-default.green-bg:not(.valid):not(.error) ~ .input-label {
    color: $font-color;
  }

  .input-label {
    @extend .label-base;
    @extend .smooth-transition;
    color: #777;
    pointer-events: none;
  }
  // label.error {
  //   @extend .label-base;
  //   @extend .label-moved;
  //   color: $red;
  //   right: 10px;
  //   left: auto;
  // }

  .move-label .input-label,
  .move-label ~ .input-label,
  .input-default.error ~ .input-label,
  .input-default.valid ~ .input-label {
    @extend .label-moved;
  }
  .input-default:-webkit-autofill ~ .input-label {
    @extend .label-moved;
  }
  // .input-default.error ~ .input-label {
  /*display: none;*/
  // }
  .input-default[required] ~ .input-label:after {
    content: "*";
    color: $red;
    margin-left: 3px;
  }
  /*Show/Hide password*/
  .input-wrapper .show-hide-password {
    content: " ";
    background-size: 100% 100%;
    display: block;
    height: 22px;
    width: 22.5px;
    position: absolute;
    top: 50%;
    right: 0;
    margin-right: 10px;
    transform: translateY(-50%);
    // background-image: url("/assets/images/icon-eye-open.png") !important;
  }
  // .input-wrapper .show-hide-password.visible {
  // background-image: url("/assets/images/icon-eye-close.png") !important;
  // }
  .valid ~ .show-hide-password,
  .error ~ .show-hide-password {
    margin-right: 40px;
  }
  /*date inputs*/

  .date-inputs-groupper .input-wrapper {
    float: left;
    width: calc(33.33% - 7px);
    margin-left: 10px;
  }
  .date-inputs-groupper .input-wrapper:first-child {
    margin-left: 0px;
  }
  // .date-inputs-groupper label.error {
  //   display: none !important;
  // }
  .date-inputs-groupper .input-label {
    display: block !important;
  }
  .date-input .input-container:after {
    content: "\f133";
    font-family: "Font Awesome 5 Free", sans-serif;
    color: $dark-grey;
    font-weight: 100;
    position: absolute;
    font-size: 15px;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    margin-right: $global-margin * 2;
  }
  .date-input input[name="postTargetDeadline"] {
    padding-right: 40px;
  }
  /*ERROR - VALID*/

  .fake-input.error {
    border: 0 !important;
    background: none !important;
    color: $red !important;
  }
  .fake-input ~ label.error {
    color: $error-color;
  }
  // .input-default.error ~ .input-icon {
  //   background-image: url("/assets/images/icon-error.png") !important;
  // }
  // .input-default.valid ~ .input-icon {
  //   background-image: url("/assets/images/icon-correct.png") !important;
  // }

  .invalid {
    color: $red;
  }

  .valid-input input,
  .valid-input select,
  .valid-input textarea {
    border: 1px solid $green;
  }
  .invalid-input input,
  .invalid-input select,
  .invalid-input textarea {
    border: 1px solid $red;
  }

  @mixin forms() {
    .input-wrapper .show-hide-password {
      height: 35px;
      width: 37px;
    }
  }

  .mobile-only-container {
    @include forms();
  }

  /*coloured buttons*/
  .btn-bordered {
    border: 1px solid;
  }

  .btn-grey {
    @extend .btn-bordered;
    color: $font-color;
    background-color: $light-grey;
    @include themes($themes) {
      background-color: $light-grey;
    }
    border: $light-border !important;
  }

  .btn-dark-grey {
    @extend .btn-bordered;
    color: $white;
    background-color: $dark-grey;
    @include themes($themes) {
      background-color: $dark-grey;
    }
    border: $light-border !important;
  }

  .btn-black {
    @extend .btn-bordered;
    color: $white;
    @include themes($themes) {
      background-color: $black !important;
    }
    border: $light-border !important;
  }

  .btn-transparent {
    background: none !important;
    color: initial;
    border-color: transparent !important;
    outline: 0;
    @include themes($themes) {
      background: none;
      border-color: transparent;
      color: initial;
    }
  }

  .btn-coloured {
    &:hover,
    &:focus,
    &:active,
    &.active {
      filter: brightness(130%);
    }
  }
  .btn-success {
    @extend .btn-coloured;
    color: #fff;
    background-color: $success-color !important;
  }
  .btn-info {
    @extend .btn-coloured;
    color: #fff;
    background-color: $info-color !important;
  }
  .btn-error {
    @extend .btn-bordered;
    color: #fff;
    background-color: $error-color !important;
  }
  .btn-warning {
    @extend .btn-coloured;
    color: #fff;
    background-color: $warning-color !important;
  }

  .btn-outline {
    @extend .btn-bordered;
    @extend .btn-coloured;
    background: none;
    color: $default-primary-color !important;
    border-color: $default-primary-color !important;
    @include themes($themes) {
      color: themed("primary-color") !important;
      border-color: themed("primary-color") !important;
    }
  }
  .btn-outline,
  .btn.btn-outline {
    &:hover,
    &:focus,
    &:active,
    &.active {
      background: none;
    }
  }
  .btn.btn-outline {
    background: none;
  }
  .btn-warning-outline {
    @extend .btn-outline;
    color: $warning-color !important;
    border-color: $warning-color !important;
    @include themes($themes) {
      color: $warning-color !important;
      border-color: $warning-color !important;
    }
  }
  .btn-success-outline {
    @extend .btn-outline;
    color: $success-color !important;
    border-color: $success-color !important;
    @include themes($themes) {
      color: $success-color !important;
      border-color: $success-color !important;
    }
  }
  .btn-info-outline {
    @extend .btn-outline;
    color: $info-color !important;
    border-color: $info-color !important;
    @include themes($themes) {
      color: $info-color !important;
      border-color: $info-color !important;
    }
  }

  .btn-black-outline {
    @extend .btn-outline;
    color: $black !important;
    border-color: $black !important;
    background-color: $white !important;
    @include themes($themes) {
      color: $black !important;
      border-color: $black !important;
    }
  }
  .btn-error-outline {
    @extend .btn-outline;
    color: $error-color !important;
    border-color: $error-color !important;
    @include themes($themes) {
      color: $error-color !important;
      border-color: $error-color !important;
    }
  }
  .btn-grey-outline {
    @extend .btn-outline;
    background: none;
    color: $dark-grey !important;
    border-color: $dark-grey !important;
    @include themes($themes) {
      color: $dark-grey !important;
      border-color: $dark-grey !important;
    }
  }

  /*end new styles*/
  ::-webkit-inner-spin-button {
    display: none;
  }

  /*validation styles*/

  .input-wrapper.error {
    input,
    textarea,
    select,
    .select-filter .inputs-group,
    .editor-container .editor-content,
    .slider {
      border-color: $red;
    }
    .input-container:after,
    .select-filter .inputs-group:after,
    .editor-container:after {
      content: "\f12a";
      color: $red;
      font-weight: 900;
    }
    .select-filter-icon-search {
      display: none;
    }
  }

  .input-wrapper.error.date-input:after {
    content: "";
  }

  form.loading .input-wrapper,
  .input-wrapper.loading {
    .input-container:after,
    .editor-container:after,
    .select-filter .inputs-group:after {
      animation: fa-spin 2s linear infinite;
      margin-top: $global-margin * -1 !important;
      content: "\f1ce" !important;
    }
    .select-filter .inputs-group button.select-filter-btn-input {
      display: none;
    }
  }

  .input-wrapper.valid {
    input,
    textarea,
    select,
    .editor-container .editor-content,
    .select-filter .inputs-group {
      border-color: $green;
    }
    .input-container:after,
    .editor-container:after,
    .select-filter .inputs-group:after {
      content: "\f00c";
      color: $green;
      font-weight: 900;
    }
    .select-filter-icon-search {
      display: none;
    }
  }
  .input-wrapper.valid.date-input:after {
    content: "";
  }
  .input-error {
    color: $red;
    font-size: $small-font-size;
  }
  .input-container {
    position: relative;
  }
  .input-container:after,
  .editor-container:after,
  .select-filter .inputs-group:after {
    position: absolute;
    top: 50%;
    right: 0;
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateY(-50%);
    margin: 0 20px;
  }
  /*IE HACKS*/
  /*IE hack*/
  .move-label .input-label,
  .input-default.error ~ .input-label,
  .input-default.valid ~ .input-label,
  // label.error {
  //   top: 13px;
  //   transform: translateY(-100%);
  //   line-height: 18px;
  //   font-size: 10px;
  // }
  // label.error {
  //   left: auto;
  //   right: 10px;
  // }
  .input-error {
    color: $red;
  }

  /*fake inputs*/
  .fake-input.checkbox,
  .fake-input.radio {
    position: relative;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: "Font Awesome 5 Free", sans-serif;
  }
  .fake-input input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  .fake-input:before {
    content: "\f0c8";
  }
  .fake-input.active:before {
    content: "\f14a";
  }
  .fake-input.partial:before {
    content: "\f146";
  }
  .partial .fake-input:before {
    content: "\f146";
  }

  /*remove key icon in password field (Safari)*/
  input::-webkit-credentials-auto-fill-button {
    visibility: hidden;
  }
  /*remove grey background for select (Safari)*/
  // select {
  //   -webkit-appearance: none;
  //   background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+);
  //   background-color: $light-grey;
  //   background-position: 100% 50%;
  // }

  select::-ms-expand {
    display: none;
  }

  /*password visible toggle*/
  button.toggle-visibility {
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    height: 33px;
    background: transparent !important;
    color: $black !important;
  }
  .input-wrapper.error button.toggle-visibility,
  .input-wrapper.valid button.toggle-visibility {
    padding-right: 30px;
  }

  /*password strength*/
  .password-strength-container {
    overflow: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .password-strength-container .strength {
    font-size: 0.9em;
    text-transform: capitalize;
  }
  .password-strength-container .meter {
    height: 10px;
    margin-top: 5px;
    position: relative;
    background: $global-border;
    border-radius: $border-radius;
  }
  .password-strength-container .meter > .password-bar {
    display: block;
    height: 100%;
    border-radius: $border-radius;
    position: relative;
    overflow: hidden;
  }
  .password-strength-container .meter > .strong {
    background-color: $green;
    border-radius: $border-radius;
    @extend .smooth-transition;
  }
  .password-strength-container .meter > .medium {
    background-color: $yellow;
    @extend .smooth-transition;
  }
  .password-strength-container .meter > .weak {
    background-color: $red;
    @extend .smooth-transition;
  }

  // .input-wrapper.validation-required:not(.input-wrapper.validation-required) {
  //   label:after {
  //     content: "*";
  //     margin-left: calc(var(--unit) / 2);
  //     @include themes($themes) {
  //       color: themed("active-color");
  //     }
  //   }
  // }

  .hide {
    display: none !important;
  }
  .show {
    display: flex !important;
  }
  .input-full-width {
    flex-grow: 1;
  }
  .icon-input-mr-top {
    margin-top: 2rem;
  }
  .icon-input-mr-up {
    margin-top: -0.25rem;
  }

  /* schedule */
  .delete-button-schedule {
    height: 48px;
    .delete-icon:before {
      color: $white;
      margin-right: 0;
    }
  }

  .autosave {
    text-align: right;
    font-style: italic;
    font-size: $small-font-size;
    padding-bottom: calc($global-padding/2);
  }
}

.multiple-element {
  flex-wrap: wrap;
}
