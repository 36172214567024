.tag-ds {
  .color-font {
    color: var(--text-01);
  }
  &.modal-wrapper .modal.xs-modal {
    width: 480%;
    max-width: 1000px;
  }
  &.modal-wrapper .modal.xl-modal {
    width: 100%;
    max-width: 1024px;
  }
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
