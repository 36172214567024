.table-config-container {
  margin-bottom: $global-margin * 2;
}
.table-config-container .toggle-table-view {
  background-image: url("../../../assets/images/candidate-sourcing/table-view-icon.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 0 center !important;
  padding-left: 20px;
}
.table-config-container .adecco-ui-reorder {
  background-image: url("../../../assets/images/candidate-sourcing/reorder-icon.svg") !important;
  background-color: transparent !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  border-radius: 0 !important;
  width: 9px !important;
  height: 12px !important;
  padding: 0 !important;
  margin: 9px !important;
  min-width: 9px;
}

.table-config-container .adeccoui-dropdown-btn {
  margin-right: 0;
}
.table-config-container .adeccoui-dropdown-menu {
  right: 0;
  left: auto;
}

.table-config-container .adeccoui-dropdown-item {
  display: flex;
  padding: 10px 5px;
}
.table-config-container .adeccoui-dropdown-item.active,
.table-config-container .adeccoui-dropdown-item:active {
  color: inherit !important;
  text-decoration: none;
}
.table-config-container .adeccoui-dropdown-item .input-wrapper {
  margin: 3px;
  padding-right: 60px;
  position: relative;
  flex: 1;
}
.table-config-container .adeccoui-dropdown-item .input-wrapper .d-flex .switch {
  order: 1;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.table-config-container .adeccoui-dropdown-item .input-wrapper .d-flex .switch-title {
  order: 0;
  margin: 0 !important;
  margin-right: 10px !important;
}

.table-config-container .adeccoui-dropdown-item .input-wrapper label {
  margin: 0;
}

.table-config-container .toggle-container .toggle-pill {
  width: calc(6 * var(--unit));
  min-width: calc(6 * var(--unit));
  order: 0;
  flex: 1;
}
.table-config-container .toggle-container label {
  order: 1;
}

.tag-ds {
  .table-config-container {
    margin-bottom: 0;
  }
}
.adecco-ui {
  .table-config-container .toggle-table-view {
    padding-left: 15px;
  }
  .table-config-container .dropdown-item {
    padding: 0.35rem 0.5rem;
    display: flex;
    display: flex;
    align-items: center;
    &:first-child {
      margin-top: 0.25rem;
    }
  }
  .table-config-container .dropdown-item .input-wrapper-checkbox {
    margin-bottom: 0;
  }
  .table-config-container .dropdown-item label {
    margin-bottom: 0;
  }
  .table-config-container .dropdown-item .adecco-ui-reorder {
    margin: 0 !important;
    margin-right: 5px !important;
  }
}