/********** START FONT **********/
.adecco-ui {
  .global-font {
    @include themes($themes) {
      font-family: themed("global-font-family");
    }
    font-size: $global-font-size;
    line-height: $global-line-height;
    font-weight: 400;
    color: $font-color;
    @media (max-width: $mobile) {
      font-size: $small-font-size;
    }
  }
  .headers {
    @include themes($themes) {
      font-family: themed("special-font-family");
    }
    margin: 0 0 $global-margin * 2 0;
  }
  @include themes($themes) {
    font-family: themed("global-font-family");
  }
  @extend .global-font;

  h1,
  .h1 {
    @extend .headers;
    font-size: 29px;
    line-height: 23px;
    font-weight: $font-weight-bold;
    @media (max-width: $mobile) {
      font-size: 25px;
    }
  }
  h2,
  .h2 {
    @extend .headers;
    font-size: 19px;
    line-height: 23px;
    font-weight: $font-weight-bold;
    @media (max-width: $mobile) {
      font-size: 17px;
    }
  }
  h3,
  .h3 {
    @extend .headers;
    font-size: 18px;
    line-height: 23px;
    font-weight: $font-weight-bold;
    @media (max-width: $mobile) {
      font-size: 17px;
    }
  }
  h4,
  .h4 {
    @include themes($themes) {
      font-family: var(--fontFamily);
    }
    font-size: 16px;
    line-height: 21px;
    font-weight: $font-weight-bold;
  }
  h5,
  .h5 {
    @include themes($themes) {
      font-family: var(--fontFamily);
    }
    font-size: 14px;
    line-height: 18px;
    font-weight: $font-weight-bold;
  }
  h6,
  .h6 {
    @include themes($themes) {
      font-family: var(--fontFamily);
    }
    font-size: 14px;
    line-height: 18px;
    font-weight: normal;
  }
  p,
  .p {
    @extend .global-font;
    margin: 0 0 $global-margin * 2 0;
  }
  small,
  .small {
    font-size: $small-font-size;
    line-height: $global-line-height;
  }
  a {
    color: $link-color;
    text-decoration: none;
    @extend .smooth-transition-color;
  }
  a:hover {
    text-decoration: underline;
    color: $link-color;
  }
  a:focus,
  a.active {
    text-decoration: underline;
    color: $link-color;
  }
  label {
    font-size: $global-font-size;
    line-height: $global-line-height;
    font-weight: 600;
    @media (max-width: $mobile) {
      font-size: $small-font-size;
    }
  }

  .title {
    padding-bottom: $global-padding;
    border-bottom: $global-border;
  }
}
