#footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: inherit;
  height: var(--footer-height);
  line-height: var(--footer-height);
  text-align: center;
  font-weight: 700;
  color: var(--text-02);
  background-color: var(--white);
  left: 0;
  z-index: 5;
  &.start-footer {
    color: white;
    background: var(--candidatePublicBackgroundEnd);
  }
  .link-version {
    justify-content: center;
    text-decoration: none;
    text-transform: none;
    color: var(--text-02);
    font-weight: 700;
  }
}
