.tag-ds {
  .tag-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 16px;
    gap: 4px;
    height: 24px;
    /* Surface/Feedback/Alert */
    background: #fff0cc;
    border-radius: 16px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .pill {
    @extend .tag-button;
    min-width: 100px;
    display: inline-block;
    color: var(--text-01);
    font-weight: 600;
    text-align: center;
    margin: 0;
    font-size: 10px;
  }
  .Online,
  .Published,
  .Running,
  .open {
    background-color: var(--success400);
    @include themes($themes) {
      background-color: var(--success400);
    }
  }
  .Offline,
  .Paused,
  .closed {
    background-color: var(--error400);
    @include themes($themes) {
      background-color: var(--error400);
    }
  }
  .Archived,
  .Ended,
  .expired {
    background-color: var(--primary200);
    @include themes($themes) {
      background-color: var(--primary200);
    }
  }
  .Draft,
  .draft {
    background-color: var(--alert400);
    @include themes($themes) {
      background-color: var(--alert400);
    }
  }
  .Schedule,
  .Scheduled,
  .schedule {
    background-color: var(--tertiary200);
    @include themes($themes) {
      background-color: var(--tertiary200);
    }
  }
  .Closed {
    background-color: var(--primary200);
    @include themes($themes) {
      background-color: var(--primary200);
    }
  }
  .Closing {
    background-color: var(--alert100);
    @include themes($themes) {
      background-color: var(--alert100);
    }
  }
  .release-notes {
    .preview-logo {
      max-width: 100%;
      max-height: 100px;
    }
  }
}
