body {
  .dis-consulting-theme {
    //Style
    --breadcrumb-height: 50px;
    --globalPaddingButton: 10px;
    --globalPaddingCard: 25px;
    --globalBorderRadius: 30px;

    --turquoise: #5cb8b2;
    --turquoise90: rgba(92, 184, 178, 0.9);
    --turquoise70: rgba(92, 184, 178, 0.7);
    --turquoise50: rgba(92, 184, 178, 0.5);
    --turquoise30: rgba(92, 184, 178, 0.3);
    --turquoise10: rgba(92, 184, 178, 0.1);

    --primaryColor: var(--white);
    --headerColor: var(--primaryColor);
    --secondaryColor: var(--turquoise);
    --componentColor: var(--secondaryColor);
    --titleHomeProcessColor: var(--black);
    --tabSelectedColor: var(--componentColor);

    //Fonts colors
    --headerFontColor: var(--gray800);
    --breadcrumbFontColor: var(--secondaryColor);
    --linkFontColor: var(--primaryColor);
    --tableHeaderColor: var(--gray800);

    //Button colors
    --primaryButtonColor: var(--secondaryColor);
    --secondaryButtonColor: var(--primaryColor);
    --hoverButtonColor: #48a19b;
    --disableButtonColor: #c8c7c7;
    --focusButtonColor: #782f40;

    //Active buttons
    --primaryButtonFontColor: var(--primaryColor);
    --primaryButtonBorderColor: var(--primaryButtonColor);

    --secondaryButtonFontColor: var(--primaryButtonColor);
    --secondaryButtonBorderColor: var(--primaryButtonColor);

    //Hover Buttons
    --primaryButtonHoverColor: var(--hoverButtonColor);
    --primaryButtonHoverFontColor: var(--primaryColor);
    --primaryButtonHoverBorderColor: var(--hoverButtonColor);

    --secondaryButtonHoverColor: var(--primaryColor);
    --secondaryButtonHoverFontColor: var(--hoverButtonColor);
    --secondaryButtonHoverBorderColor: var(--hoverButtonColor);

    //Disable Buttons
    --primaryButtonInactiveColor: var(--disableButtonColor);
    --primaryButtonInactiveFontColor: var(--primaryColor);
    --primaryButtonInactiveBorderColor: var(--disableButtonColor);

    --secondaryButtonInactiveColor: var(--primaryColor);
    --secondaryButtonInactiveFontColor: var(--disableButtonColor);
    --secondaryButtonInactiveBorderColor: var(--disableButtonColor);

    --mainColor: var(--white);
    --secondaryColor: var(--turquoise);

    --dropdownBackgroundColor: var(--white);
    --dropdownElementHoverColor: #e2e2e2;
    --mainLogo: url("../images/dis-consulting-theme/common/logo.png");
    --mainLogoColored: url("../images/dis-consulting-theme/common/logo.png");
    --homeTopImage: none;
    --buttonPrimaryActiveColor: var(--turquoise);
    --buttonPrimaryBorderColor: var(--turquoise);
    --buttonPrimaryActiveFontColor: var(--white);
    --buttonPrimaryHoverColor: #48a19b;
    --buttonPrimaryHoverBorderColor: #48a19b;
    --buttonPrimaryHoverFontColor: var(--white);
    --buttonPrimaryPressedColor: #782f40;
    --buttonPrimaryPressedFontColor: var(--white);
    --buttonPrimaryInactiveColor: #c8c7c7;
    --buttonPrimaryInactiveFontColor: var(--white);
    --buttonSecondaryActiveColor: var(--white);
    --buttonSecondaryBorderColor: #5cb8b2;
    --buttonSecondaryActiveFontColor: #5cb8b2;
    --buttonSecondaryHoverColor: var(--white);
    --buttonSecondaryHoverBorderColor: #48a19b;
    --buttonSecondaryHoverFontColor: #48a19b;
    --buttonSecondaryPressedColor: #782f40;
    --buttonSecondaryPressedFontColor: #782f40;
    --buttonSecondaryInactiveColor: #c8c7c7;
    --buttonSecondaryInactiveFontColor: #c8c7c7;
    --buttonBlueActiveColor: #00b5f0;
    --buttonBlueActiveFontColor: var(--turquoise);
    --buttonBlueHoverColor: #0096ef;
    --buttonBlueHoverFontColor: var(--white);
    --buttonBluePressedColor: #196fc9;
    --buttonBluePressedFontColor: var(--white);
    --helpButton: url("../images/dis-consulting-theme/candidate/help-button.png");
    --phaseCompleted: url("../images/dis-consulting-theme/candidate/phase-completed.png");
    --phaseFailed: url("../images/dis-consulting-theme/candidate/phase-failed.png");
    --dropdown: url("../images/dis-consulting-theme/common/dropdown.png");
    --candidatePublicBackgroundStart: #5cb8b2;
    --candidatePublicBackgroundEnd: #48a19b;

    --candidateDownloadPdf: url("../images/candidate-web/download-pdf.png");
    --adminServiceFeatureBackground: var(--gray100);
    --adminServiceFeatureBorder: var(--gray200);

    //Font Family
    --fontFamily: "Kievit";
    --fontFamilyUrl: "https://fonts.googleapis.com/css?family=Comfortaa:300,400,500,600,700&display=swap";
    --fontFamilyHeaders: "Bebas Neue";
    --fontFamilyBody: "Kievit";
  }
}
