.adecco-ui {
  &.notifcations-container,
  .notifcations-container {
    position: fixed;
    top: 4rem;
    left: 50%;
    width: 100%;
    max-width: 1140px;
    display: flex;
    z-index: 1000; // above sidebar and modal
    flex-direction: column-reverse;
    transform: translateX(-50%);
  }

  .notifications-item {
    @extend .smooth-transition;
    // background: #fff;
    // margin-top: 1em;
    // margin-bottom: 1em;
    // padding-top: 1em;
    // padding-left: 2.5em;
    // padding-bottom: 1em;
    // position: relative;
    // overflow: hidden;
    // border-radius: $border-radius;
    // &.success {
    //   background-color: rgba(200, 247, 197, 0.8);
    //   color: rgba(30, 130, 76, 1) !important;
    //   border: 1px solid rgba(30, 130, 76, 0.3) !important;
    //   & > .inner > * {
    //     color: rgba(30, 130, 76, 1) !important;
    //   }
    // }
    // &.warning {
    //   background-color: rgba(253, 227, 167, 0.8);
    //   color: rgba(211, 84, 0, 1) !important;
    //   border: 1px solid rgba(211, 84, 0, 0.3) !important;
    //   & > .inner > * {
    //     color: rgba(211, 84, 0, 1) !important;
    //   }
    // }
    // &.error {
    //   background-color: rgba(241, 169, 160, 0.8);
    //   color: rgba(150, 40, 27, 1) !important;
    //   border: 1px solid rgba(150, 40, 27, 0.3) !important;
    //   & > .inner > * {
    //     color: rgba(150, 40, 27, 1) !important;
    //   }
    // }
    // &.info {
    //   background-color: rgba(197, 239, 247, 0.8);
    //   color: rgba(37, 116, 169, 1) !important;
    //   border: 1px solid rgba(37, 116, 169, 0.3) !important;
    //   & > .inner > * {
    //     color: rgba(37, 116, 169, 1) !important;
    //   }
    // }
    // &.default {
    //   background-color: rgba(204, 204, 204, 0.8);
    //   color: black !important;
    //   border: 1px solid rgba(0, 0, 0, 0.3) !important;
    //   & > .inner > * {
    //     color: black !important;
    //   }
    // }
    @extend .smooth-transition;
  }

  .notifications-close {
    position: absolute;
    top: 1em;
    right: 1em;
    cursor: pointer;
  }

  .notification-type-icon {
    position: absolute;
    top: 15px;
    left: 0.5em;
    font-size: 19px;
  }

  .notifications-item-shown {
    max-height: 100vh;
  }

  .notifications-item-hidden {
    max-height: 0em;
  }
}

.tag-ds {
  &.notifications-container,
  .notifications-container {
    position: fixed;
    top: calc(3 * var(--unit));
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 1140px;
    display: flex;
    z-index: 1000; // above sidebar and modal
    flex-direction: column-reverse;
    gap: calc(2 * var(--unit));
    .notification {
      position: relative;
      @extend .smooth-transition;
      &_info,
      &_read,
      &_error,
      &_warning,
      &_success {
        position: relative;
        @extend .smooth-transition;
      }
    }
  }
  .static-notification {
    position: relative !important;
    z-index: 0 !important;
    top: 0 !important;
    transform: none !important;
    left: 0 !important;
    &_read,
    &_info {
      position: relative !important;
    }
  }
}
