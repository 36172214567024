//fixes the dropdown of the tabs on mobile not showing the menu completly, example in /recruitment  at <768px
.tag-ds .tabs .tab-list .dropdown .dropdown-menu,
.tag-ds.tabs .tab-list .dropdown .dropdown-menu {
  width: auto !important;
}
.tag-ds .tabs .tab-list .dropdown .dropdown-menu .tab-list-item,
.tag-ds.tabs .tab-list .dropdown .dropdown-menu .tab-list-item {
  width: auto !important;
}

//in /sourcing the tabs "All job descriptions,My branch job descriptions,Job description templates"
// do not mutate into a dropdown, instead the overflow the page and dont' show the text
@media (max-width: 672px) {
  #job-description-listing-home .tab-list {
    display: block;
  }
}

//in job description creation (/sourcing/2ffbd099-37a7-456a-89d6-43fa05d0cb41) the steps don't show correctly on mobile
//stepper not working for mobile
@media (max-width: 672px) {
  .job-description-form-ui .stepper-mobile {
    display: none;
  }
}

//modals change their widht depending on the content
//like users modal /administrator/users -> add user
//or like /administrator/video-library -> add vide library
//or like /administrator/position -> add position
.tag-ds .modal-wrapper,
.tag-ds.modal-wrapper {
  display: flex;
  align-content: center;
  align-items: center;
}
.tag-ds .modal-wrapper:before,
.tag-ds.modal-wrapper:before {
  position: fixed;
}
.tag-ds.modal-wrapper .modal {
  max-width: 600px;
  width: 100%;
  left: auto !important;
  position: relative !important;
  top: auto !important;
  transform: none;
  display: block;
  margin: 0 auto !important;
  max-height: 100vh;
}
//long modals don't fit
.tag-ds.modal-wrapper .modal-body {
  overflow-y: auto;
  margin-top: calc(2 * var(--unit));
  max-height: calc(100vh - 32px - 40px - 80px - 60px - 60px); //full screen - side of the modal header - modal margin from top - space for the modal footer - a bit of air
  min-height: 275px;
}

//Fix the modal actions of the JDTemplates table (Are need because not should be fixed (should be absolute))
.tag-ds {
  .table-menu-dropdown {
    .dropdown-menu {
      position: absolute !important;
      top: auto !important;
      left: auto !important;
    }
  }
}

//apply same style to footer as when the footer matches this rule from tag-ds: .tag-ds .modal > .modal-header + .modal-footer
// so it applies always
.tag-ds.modal-wrapper .modal-footer {
  margin-top: calc(6 * var(--unit));
}

/*tooltips not displayed in forms within a modal*/
.tooltip {
  z-index: 101 !important;
}

//people uses the class Link in divs and other non-clickable items, breaking the accesibility
//we need to use a clickable item (a button) that looks like a link
//example in /administrator/screening-questions -> add question modal "+ Add answer"
.tag-ds button.link,
.tag-ds .btn.link {
  background: none !important;
  padding: 0 !important;
  display: inline-block !important;
  min-width: 0 !important;
  text-decoration: none !important;
  height: auto !important;
}
.tag-ds .drawer-wrapper,
.tag-ds.drawer-wrapper {
  z-index: 3 !important;
  width: 328px;
}

// for the filters in the Results table to be responsive
.tag-ds .filtered-search .filtered-search-top,
.tag-ds.filtered-search .filtered-search-top {
  flex-wrap: wrap;
}
// for select chips styled
.filtered-search-top .chip-filters {
  width: 100%;
  max-width: 200px !important;
}
.filtered-search-top .chip-filters .chip {
  text-align: left !important;
  text-overflow: ellipsis;
}
/*main sidebar buttons wrong display if text is too long*/
.tag-ds .sidebar-button,
.tag-ds .sidebar-button_disabled,
.tag-ds .sidebar-button_selected,
.tag-ds .sidebar-button_selected_disabled {
  max-height: none !important;
}

/*content switcher buttons wrong display if text is too long*/
.tag-ds .content-switcher-items,
.tag-ds .content-switcher-items_divider,
.tag-ds .content-switcher-items_small,
.tag-ds .content-switcher-items_small_divider {
  max-height: none !important;
}
.tag-ds button.content-switcher-item-button {
  max-height: none !important;
  height: auto !important;
}

/*select inputs don't have same height as rest of the inputs*/
.tag-ds .input-container select:not([multiple]) {
  height: 40px;
}

/*replica of the layout of inputs from tag-ds to be reused accross missing inputs that don't have styles (like time, date, select...)*/
.tag-ds-base-input {
  appearance: none;
  background: var(--backgrounds-01);
  border-radius: 8px;
  max-width: inherit;
  outline: 0;
  width: 100%;
  font-family: var(--body-medium-font-family);
  font-size: var(--body-medium-font-size);
  font-weight: var(--body-medium-font-weight);
  letter-spacing: var(--body-medium-letter-spacing);
  line-height: var(--body-medium-line-height);
  max-height: 40px;
  padding: 8px 16px;
  text-decoration: var(--body-medium-text-decoration);
  text-transform: var(--body-medium-text-case);
}

/*number inputs have the arrows too far from the right*/
.tag-ds .input-container .input,
.tag-ds .input-container input,
.tag-ds .input-container_disabled .input,
.tag-ds .input-container_disabled input,
.tag-ds .input-container_error .input,
.tag-ds .input-container_error input,
.tag-ds.input-container .input,
.tag-ds.input-container input,
.tag-ds.input-container_disabled .input,
.tag-ds.input-container_disabled input,
.tag-ds.input-container_error .input,
.tag-ds.input-container_error input {
  padding: 8px 8px 8px 16px !important;
}

/*re-add padding to searchbox in results that have a search input*/
.tag-ds .searchbox_small,
.tag-ds input[type="search"].searchbox_small,
.tag-ds.input[type="search"].searchbox_small {
  padding: var(--unit) calc(2 * var(--unit)) var(--unit) calc(6 * var(--unit)) !important;
}
.tag-ds .searchbox,
.tag-ds input[type="text"].searchbox,
.tag-ds.input[type="text"].searchbox {
  padding: 16px 16px 16px 48px !important;
  min-width: fit-content !important;
}

// this definitions are introduced because :has is not working in FF
// first one is defined .tag-ds .chip:has(.material-icons.right) but it's not working in FF
// second one is for .button-interactive .tag-ds .input-container .button-interactive:has which is also not working in FF
// this definitions sets the padding to the same as the rest of the chips if in FF.
// for now only fixed inside results classes so other chips are not affected
@-moz-document url-prefix() {
  .tag-ds {
    .results-summary-container {
      .chip {
        padding: 8px 28px 8px 16px !important;
      }
    }

    .data-table-top-toolbar {
      .input-container .button-interactive {
        height: 100%;
        min-width: auto;
        padding: 16px;
        width: 56px;
      }
    }
  }
}

/*remove gloss & shadow in safary and match style with rest of the inputs*/
.tag-ds .input-container select {
  @extend .tag-ds-base-input;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+);
  background-position: 100% 50%;
  background-repeat: no-repeat;
}

/*input type time does not have styles applied from tag ds*/
.tag-ds input[type="time"] {
  @extend .tag-ds-base-input;
}

/*this is to unify pages with narrow content aligned on the left (like process creation and job creation)*/
.narrow-page-container {
  width: 100% !important;
  max-width: 750px !important;
}

/*fix layout of required, label and link inside label of a checkbox*/
.tag-ds .checkbox-container label .required,
.tag-ds .checkbox-container label .label-text,
.tag-ds .checkbox-container label .label-text a {
  display: inline;
}

.tag-ds .select-bottom-container .chip_active {
  white-space: normal !important;
  text-overflow: ellipsis !important;
}

// Fix close icon in dropdowns for Firefox browser
@-moz-document url-prefix() {
  .tag-ds .select-bottom-container .chip_active {
    padding-right: calc(4 * var(--unit)) !important;
  }
}

//Fix class Link in different line
.tag-ds .linkNoFlex {
  display: contents !important;
}

// creates padding inside grid elements
.inner {
  padding: 0 var(--unit);
}

// Fix width buttons in Wysiwyg component for Firefox browser
@-moz-document url-prefix() {
  .tag-ds .editor-commands {
    button.button-secondary.small {
      min-width: 32px !important;
    }
  }
}

/* Using 'font-weight: 600;' because 'font-weight: bolder;' is not working as expected for some users */
b,
strong {
  font-weight: 600;
}

// Disabled radio inputs
.tag-ds .radio-container_disabled {
  display: flex;
  margin: calc(2 * var(--unit)) 0;
  position: relative;
}

// To fix calendars, when have more than 5 rows of days in a month
.tag-ds .calendar {
  max-height: none !important;
}

.tag-ds input[type="number"],
.tag-ds input[type="text"],
.tag-ds input[type="tel"],
.tag-ds select {
  min-width: fit-content;
}

.tag-ds .dropdown-item-icon {
  padding: 8px 32px 8px 16px;
  display: flex;
  align-items: center;
}

.tag-ds .dropdown-item-icon span {
  font-size: 16px;
  margin-right: 8px;
}

.tag-ds .dropdown-item-process::before {
  content: "" !important;
}

.tag-ds.results.data-table-wrapper .info-icon {
  font-family: "Material Icons Round";
  color: var(--text-01);
}
.tag-ds li + li {
  margin-top: 0px;
}

.tag-ds .radio-container {
  display: flex;
  margin: 16px 0;
}
.tag-ds h1,
.tag-ds h2,
.tag-ds h3,
.tag-ds h4,
.tag-ds h5,
.tag-ds h6 {
  font-family: var(--h4-desktop-font-family);
}

.tag-ds h5 {
  font-size: 16px;
}
.tag-ds h6 {
  font-size: 12px;
  font-weight: 400;
}

.tag-ds .chip_active {
  margin: 0;
}

.tag-ds .chip,
.tag-ds .chip + .chip,
.tag-ds .chip_active + .chip_active {
  margin: 0;
}
.tag-ds button.button-secondary.small:has(.material-icons:not(.left, .right)) {
  min-width: 32px;
}

.tag-ds[data-portal="true"].notification_error,
.tag-ds[data-portal="true"].notification_warning,
.tag-ds[data-portal="true"].notification_info {
  z-index: 1000;
}

.tag-ds.radio-container label {
  text-transform: none;
}

.tag-ds .grey-border {
  border: 1px solid #ccccd3;
  border-radius: var(--unit);
}

.tag-ds .video-player-modal {
  .modal-body {
    margin-top: 0;
  }
}

.tag-ds textarea {
  min-width: 100%;
}

.tag-ds .card_masonry_horizontal,
.tag-ds .card_masonry_horizontal_selected,
.tag-ds.card_masonry_horizontal,
.tag-ds.card_masonry_horizontal_selected {
  min-width: 100% !important;
}

.tag-ds .video-url {
  border-radius: var(--unit);
  border: none;
  height: 208px;
}

.tag-ds .radio-container.error input[type="radio"] {
  border-color: var(--full-palette-semantic-error-600) !important;
}

.tag-ds .action-buttons {
  z-index: 2 !important;
}

.tag-ds .datepicker-wrapper .dropdown-menu {
  z-index: 3;
}

#message > a,
#end-stage > * > a {
  text-transform: none !important;
}

.tag-ds .info-icon {
  font-size: calc(2 * var(--unit));
  color: var(--text-01)
}

.tag-ds .emprty-select-value {
  color: #ADADB5;
}

.tag-ds .select-value {
  color: #1C304B;
}

.tag-ds .grey-container {
  background-color: var(--backgrounds-02);
  border-radius: var(--unit)
}