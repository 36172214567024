.adecco-ui {
  .icon-open {
    -ms-transform: rotate(180deg); /* IE 9 */
    -webkit-transform: rotate(180deg); /* Safari 3-8 */
    transform: rotate(180deg);
  }

  .icon-close {
    -ms-transform: rotate(0deg); /* IE 9 */
    -webkit-transform: rotate(0deg); /* Safari 3-8 */
    transform: rotate(0deg);
  }

  .accordion-container .accordion-header {
    background-color: $light-grey;
    @include themes($themes) {
      background-color: $light-grey;
    }
    cursor: pointer;
  }

  .accordion-container .accordion-body {
    border: $light-border;
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    @extend .smooth-transition;
  }

  .accordion-body .inner {
    padding: $global-padding * 2;
  }
  .accordion-body {
    overflow: hidden;
  }

  .accordion-close {
    height: 0px;
    opacity: 0;
  }
  .accordion-open {
    height: autp;
  }
}
