.adecco-ui {
  .modal-body {
    padding: $global-padding * 2 $global-padding * 3;
    background-color: white;
    overflow-y: auto;
    overflow-x: auto;
    flex: 1 1 auto;
  }
  .modal-footer {
    padding: $global-padding * 2 $global-padding * 3;
    border-top: 1px solid #ccc;
  }
  .modal-footer > * {
    margin: 0;
  }
  &.modal-wrapper {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: none;
    z-index: 200;
  }

  &.modal-wrapper.modal-open {
    display: block;
  }

  &.modal {
    background-color: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 980px;
    transform: translate(-50%, -100%);
    display: flex;
    max-width: calc(100% - 20px);
    overflow-y: auto;
    flex-direction: column;
    max-height: calc(100% - 20px);
    height: auto;
    visibility: hidden;
    z-index: 200;
    border-radius: $global-padding;
  }

  &.modal.modal-open {
    visibility: visible;
    transform: translate(-50%, -50%);
    transition: transform 0.5s;
  }

  .modal-header {
    position: relative;
    background-color: $light-grey;
    border-bottom: 1px solid $light-grey;
    padding: $global-padding * 3;
  }

  .modal-border-top {
    border-top: 1px solid $light-grey;
  }

  .modal-close-icon {
    position: absolute;
    right: 1em;
    top: 1em;
    cursor: pointer;
  }

  &.modal.fixed-right {
    max-height: 100vh;
    height: 100vh;
    top: 0;
    right: 0;
    left: auto;
    transform: translate(100%, 0);
    z-index: 200;
    transition: transform 0.5s;
  }

  &.modal.fixed-left {
    top: 0;
    height: 100vh;
    max-height: 100vh;
    left: 0;
    right: auto;
    transform: translate(-100%, 0);
    z-index: 200;
    transition: transform 0.5s;
  }

  &.modal.fixed-bottom {
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    top: auto;
    max-width: 100%;
    transform: translate(0%, 100%);
    z-index: 200;
    transition: transform 0.5s;
  }

  &.modal.fixed-top {
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
    width: 100%;
    max-width: 100%;
    transform: translate(0%, -100%);
    z-index: 200;
    transition: transform 0.5s;
  }

  &.modal.fixed-right.modal-open,
  &.modal.fixed-left.modal-open,
  &.modal.fixed-top.modal-open,
  &.modal.fixed-bottom.modal-open {
    transform: translate(0, 0);
    transition: transform 0.5s;
  }

  @media (max-width: $mobile) {
    .modal {
      width: 100%;
    }
  }
}
.tag-ds {
  &.modal-wrapper .modal {
    max-width: 600px;
    & > form {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
  }
}
